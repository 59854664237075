import { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router"
import { adminGetOutletBalance, adminEditOutletBalance } from "../../components/Utils/APIManager"
import AdminOutletLogo from "../../components/AdminPages/Shared/AdminOutletLogo"
import AdminOutletTransactionCell from "../../components/AdminPages/AdminOutletTransactionPage/AdminOutletTransactionCell"

import "../../components/Styles/AdminPageStyles/adminOutletTransactionPage.scss"

const AdminOutletTransactionPage = () => {
    let history = useHistory()
    const { outletid } = useParams()

    const [outletBalance, setOutletBalance] = useState("")
    const [outletBalanceHistory, setOutletBalanceHistory] = useState([])
    const [outletName, setOutletName] = useState("---")

    const [balanceType, setBalanceType] = useState(null)
    const [balanceAmount, setBalanceAmount] = useState(null)

    useEffect(() => {
        getOutletBalance()
    }, [])

    const getOutletBalance = async () => {
        const outlet_balance_response = await adminGetOutletBalance(outletid)
        if (outlet_balance_response.data != null) {
            setOutletBalance(outlet_balance_response.data.balance.current_amount)
            setOutletBalanceHistory(outlet_balance_response.data.balance.history)
            setOutletName(outlet_balance_response.data.balance.outlet.name)
        }
    }

    const editOutletBalance = async () => {
        if (balanceType === null) {
            alert("Withdraw or Deposit is Required")
            return
        }

        if (balanceAmount === null || balanceAmount <= 0) {
            alert("Dollar amount cannot be less than one")
            return
        }

        const sendData = {
            "balance": {
                "amount": balanceAmount,
                "what": balanceType
            }
        }

        const edit_outlet_balance_response = await adminEditOutletBalance(outletid, sendData)
        if (edit_outlet_balance_response.data != null) {
            alert("Transaction was successful")
            history.go(0) // Reload Current Route
        }
    }

    return (
        <div className="admin-outlet-transaction-page-container">
            
            <AdminOutletLogo outletid={outletid} type={"balance"} outletBalance={outletBalance} outletName={outletName} />

            <div className="aot-section">
                <label>Manage Balance:</label>

                <div className="aot-balance-type-container">
                    <input type="radio" name="balance_type" onChange={() => setBalanceType("withdraw")} /> <span>Withdraw</span>
                    <input type="radio" name="balance_type" onChange={() => setBalanceType("deposit")} /> <span>Deposit</span>
                </div>

                <div className="aot-balance-input-container">
                     $<input type="number" inputMode="numeric" placeholder={"Dollar Amount"} onChange={(e) => setBalanceAmount(e.target.value)}></input>
                    <button onClick={() => editOutletBalance()}>Submit Transaction</button>
                </div>
            </div>

            <div className="aot-section">
                <label>Transaction History:</label>

                <table className="aot-table">
                    <tbody>
                        { outletBalanceHistory.map(transaction => (
                            <AdminOutletTransactionCell transaction={transaction} />
                        )) }
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default AdminOutletTransactionPage
