import { useContext } from "react"
import { useHistory } from "react-router"
import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"
import { dispensaryLocationFormat } from "../Utils/StringFormatters"

import AWSImage from "../Shared/AWSImage"

import "../Styles/DispensariesPageStyles/dispensariesTableCell.scss"

const DispensariesTableCell = ({ dispensary, location, locationID }) => {
    const { getGoToRouteForOrganization } = useContext(RoutesContext)
    const { resetCart, checkCartLocationAllowed } = useContext(CartContext)
    let history = useHistory()

    const dispensariesTableCellClicked = () => {
        const locationAllowed = checkCartLocationAllowed(locationID)
        if (dispensary._id && locationAllowed) {

            history.push(getGoToRouteForOrganization(`/dispensary/${dispensary._id}/${locationID}`))
        }

        if (!locationAllowed) {
            if (window.confirm("You have items in your cart belonging to another dispensary. You may only order from 1 dispensary at a time. Would you like to clear your cart and order from this dispensary location instead?")) {
                resetCart()
                history.push(getGoToRouteForOrganization(`/dispensary/${dispensary._id}/${locationID}`))
            }else{
                return
            }
        }
    }

    return (
        <tr className="dispensaries-table-cell" key={locationID}>
            <td onClick={dispensariesTableCellClicked}>
                <AWSImage fileKey={dispensary.logo} />
                <label style={{fontWeight: "600"}}>{dispensary.name}</label>
                <br></br>
                <br></br>
                <label>{ dispensaryLocationFormat(location) }</label>
                <div className="mar-vert-24"></div>
                <hr></hr>
            </td>
        </tr>
    )
}

export default DispensariesTableCell
