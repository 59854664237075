import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router"
import { RoutesContext } from "../components/Utils/RoutesManager"
import { CartContext } from "../components/Utils/CartManager"
import { centsToDollarsDisplay } from "../components/Utils/StringFormatters"
import AWSImage from "../components/Shared/AWSImage"
import Moment from "react-moment"
import LogoSmall from "../components/Images/logo-small.png"

import "../components/Styles/OrderStatusPageStyles/orderStatusPage.scss"

const OrderStatusPage = () => {
    let history = useHistory()
    const { getGoToRouteForOrganization } = useContext(RoutesContext)
    const { resetCart } = useContext(CartContext)

    const [order, setOrder] = useState(null)
    const [orderOutlet, setOrderOutlet] = useState(null)
    
    useEffect(() => {

        const checked_out = history.location.state?.checked_out
        if (checked_out === undefined || checked_out === null) {
            history.push(getGoToRouteForOrganization("/cart"))
        }else if (checked_out) {
            resetCart()
        }

        const order_response = history.location.state?.order
        if (order_response != undefined && order_response != null) {
            setOrder(order_response)
            setOrderOutlet(order_response.outlet)
        }

    }, [])

    const calculateOrderTotal = () => {
        return centsToDollarsDisplay(order.total_in_cents + parseInt(order.customer.externalId ? order.customer.externalId : 0))
    }

    return (
        <div className="order-status-page-container">

            { order != null && 
            <div>
                <div className="os-header">
                    <label>Order<br></br>Confirmation</label>
                </div>
                
                <div className="os-info">
                    <label>Order Number: <span>{order.order_code}</span></label>
                    <label>Date/Time: <span><Moment format={"MM/DD/YY @ hh:mma"}>{order.createdAt}</Moment></span></label>
                </div>

                <div className="os-info">
                    <label className="os-info-sep">Next: <span>Our Concierge will call you directly to schedule our next steps! We try to call everyone within 4 minutes and 20 seconds!</span>
                    </label>
                </div>

                <div className="os-card">
                    <div className="os-card-info-container">
                        <div className="os-card-info">
                            <label>Order #: {order.order_code}</label>
                        </div>
                        <div className="os-card-visual">
                            <img src={LogoSmall}></img>
                        </div>
                    </div>

                    <div className="os-card-logo">
                        { orderOutlet === null &&
                            <img src={LogoSmall}></img>
                        }
                        { orderOutlet != null &&
                            <AWSImage fileKey={orderOutlet.logo} />
                        }
                    </div>

                    <div className="os-card-amount">
                        <label>{calculateOrderTotal()}</label>
                    </div>
                </div>

                <div className="os-footer">
                    <div>
                        <label>You can reach us at any time to discuss your order:</label>
                    </div>
                    
                    <div className="os-footer-b">
                        <label>303.905.5912</label>
                    </div>

                    {/* <div className="os-footer-b os-footer-u">
                        <label><a href="mailto: support@cloud9delivers.com">support@cloud9delivers.com</a></label>
                    </div> */}
                </div>
            </div>
            }
        </div>
    )
}

export default OrderStatusPage
