import { useContext } from "react"
import { Link } from "react-router-dom"
import { RoutesContext } from "../Utils/RoutesManager"
import { categoryDisplayFormat, dispensaryLocationFormat } from "../Utils/StringFormatters"
import AWSImage from "../Shared/AWSImage"
import Logo from "../Images/logo.png"

import "../Styles/DispensariesProductsPageStyles/dispensariesProductsTableHeadCell.scss"

const DispensariesProductsTableHeadCell = ({ dispensary, category, backLocation }) => {
    const { getGoToRouteForOrganization } = useContext(RoutesContext)

    return (
        <tr className="dispensaries-products-table-head-cell">
            <td>
                <div>
                    <Link to={getGoToRouteForOrganization(backLocation)}>{"< All Products"}</Link>
                    <label className="dpt-head-label">{ categoryDisplayFormat(category) }</label>
                </div>
                <div>
                    { dispensary != null &&
                        <AWSImage fileKey={dispensary.logo} />
                    }
                    { dispensary === null &&
                        <img src={Logo}></img>
                    }
                    { dispensary != null && dispensary.locations[0] != null &&
                        <div className="dpt-location">
                            <label>{dispensary.name}</label>
                            <span>{ dispensaryLocationFormat(dispensary.locations[0]) }</span>
                        </div>
                    }
                </div>
            </td>
        </tr>
    )
}

export default DispensariesProductsTableHeadCell
