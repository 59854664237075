import { useState, useContext } from "react"
import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"
import { dispensaryLocationFormat } from "../Utils/StringFormatters"
import Autocomplete from "react-google-autocomplete"
import getDistanceBetweenLocations from "../Utils/DistanceMatrixHelper"

import "../Styles/CartPageStyles/cartCheckoutTableCell.scss"
import OrderSummary from "../Shared/OrderSummary"
import { canCheckout } from "../Utils/APIManager"

const CartCheckoutTableCell = ({ props }) => {
    const { baseOrganization } = useContext(RoutesContext)
    const { cartDispensary, cartItems, calculateCartDeliveryFee } = useContext(CartContext)
    const { setDeliveryAddress, setDeliveryAddress2, setDeliveryAddressComponents } = props

    let deliveryAddress = ""
    let lastPlaceSelected = null
    const [deliveryAddressError, setDeliveryAddressError] = useState("")

    const area420Org = baseOrganization === "area420"

    const locationMap = {
        street_number: 'streetNumber',
        route: 'streetName',
        sublocality_level_1: 'city',
        locality: 'city1',
        administrative_area_level_1: 'state',
        postal_code: 'postalCode',
        country: 'country'
    }

    const deliveryAddressFieldFocused = (e) => {
        if (cartItems.length === 0) {
            alert("You must add items to your cart before selecting a delivery address")
            e.target.blur()
        }
    }

    const onPlaceSelected = async (place) => {
        const formattedDispensaryAddress = dispensaryLocationFormat(cartDispensary.locations[0])
        const dispensaryAddress = `${formattedDispensaryAddress}, USA`
        const selectedDeliveryAddress = place.formatted_address

        // Check if Dispensary Address Is Available
        if (formattedDispensaryAddress === null || formattedDispensaryAddress.length === 0) {
            alert("Please choose a dispensary to order from to calculate your delivery fee.")
            return
        }

        // Check if Selected Delivery Address is Valid
        const validPlaceSelected = validatePlaceAddressComponents(place)
        if (!validPlaceSelected) {
            alert("Delivery is only available to Residential/Business locations")
            return
        }

        //Check if Selected Delivery Address has already ordered within the last 24 hours
        const validTimeFrame = await validateDeliveryTimeFrame(place)
        if (!validTimeFrame) {
            alert("An order has already been made within the last 24 hours to the selected address.")
            return
        } 

        // Check if Delivery Address City & State is Valid Against Choosen Dispensary
        const validDeliveryCityState = validateDeliveryCityState(dispensaryAddress, selectedDeliveryAddress)
        if (validDeliveryCityState) {

            deliveryAddress = selectedDeliveryAddress
            lastPlaceSelected = place
            
            getDistanceBetweenLocations(dispensaryAddress, selectedDeliveryAddress, distanceBetweenLocationsCallback)
        }else{
            if (!area420Org) {
                alert("Delivery is only Available to locations in Denver and Aurora CO")
            }else{
                alert("Delivery is only Available in Colorado")
            }
        }
    }

    const distanceBetweenLocationsCallback = (distance) => {
        if (isNaN(distance)) {
            setDeliveryAddressError("Invalid address provided")
            setDeliveryAddress(null)
            setDeliveryAddressComponents(null)
        }else{
            const maxDistanceBetweenDeliveryLocation = 99999 // In Miles
            const outsideOfDeliveryRange = distance > maxDistanceBetweenDeliveryLocation

            setDeliveryAddressError(outsideOfDeliveryRange ? "Outside of delivery range" : "")
            setDeliveryAddress(outsideOfDeliveryRange ? null : deliveryAddress)
            setDeliveryAddressComponents(outsideOfDeliveryRange ? null : extractPlaceAddressComponents(lastPlaceSelected))

            if (outsideOfDeliveryRange === false) { 
                calculateCartDeliveryFee(distance, baseOrganization)
            }
        }
    }

    const validateDeliveryTimeFrame = async (place) => {
        let validTimeFrame = false

        const extractedAddressComponents = extractPlaceAddressComponents(place)
        const sendData = {
            "order": {
                "address": {
                    "street1": extractedAddressComponents.streetNumName,
                    "street2": "",
                    "city": extractedAddressComponents.city1,
                    "state": extractedAddressComponents.state,
                    "zip": extractedAddressComponents.postalCode
                }
            }
        }

        const can_checkout_response = await canCheckout(sendData)
        if (can_checkout_response.data != null) {
            validTimeFrame = can_checkout_response.data.canOrder
        }

        return validTimeFrame
    }

    const validateDeliveryCityState = (dispensaryAddress, selectedDeliveryAddress) => {
        //const dispensaryLocatedInDenver = dispensaryAddress.includes("Denver")
        //const dispensaryLocationInAurora = dispensaryAddress.includes("Aurora")

        const deliveryLocationInDenver = selectedDeliveryAddress.includes("Denver")
        const deliveryLocationInAurora = selectedDeliveryAddress.includes("Aurora")
        //const deliveryLocationInNorthglenn = selectedDeliveryAddress.includes("Northglenn")
        //const deliveryLocationInThornton = selectedDeliveryAddress.includes("Thornton")

        const deliveryAllowedInState = (selectedDeliveryAddress.includes("CO") || selectedDeliveryAddress.includes("Colorado")) ? true : false
        const deliveryAllowedInCity  = (deliveryLocationInDenver || deliveryLocationInAurora) ? true : false


        if (area420Org) {
            return deliveryAllowedInState
        }else{
            return (deliveryAllowedInState && deliveryAllowedInCity)
        }
    }

    const validatePlaceAddressComponents = (place) => {
        if (place === null) {
            return false
        }

        let validLocation = true

        let brokenDownAddress = {}
        place.address_components.forEach(component => {
            brokenDownAddress[locationMap[component.types[0]]] = component.long_name
        })

        if (!brokenDownAddress.hasOwnProperty("streetNumber")) {
            validLocation = false
        }else if (!brokenDownAddress.hasOwnProperty("streetName")) {
            validLocation = false
        }else if (!brokenDownAddress.hasOwnProperty("city1")) {
            validLocation = false
        }else if (!brokenDownAddress.hasOwnProperty("state")) { 
            validLocation = false
        }else if (!brokenDownAddress.hasOwnProperty("postalCode")) { 
            validLocation = false
        }

        return validLocation
    }

    const extractPlaceAddressComponents = (place) => {        
        if (place === null) {
            return null
        }
        
        let brokenDownAddress = {}
        place.address_components.forEach(component => {
            brokenDownAddress[locationMap[component.types[0]]] = component.long_name
        })

        brokenDownAddress["streetNumName"] = `${brokenDownAddress["streetNumber"]} ${brokenDownAddress["streetName"]}`
        
        return brokenDownAddress
    }

    return (
        <tr className="cart-checkout-table-cell">
            <td>
                <div className="cc-section">
                    <label className="cc-section-label">Delivery Address</label>

                    { deliveryAddressError.length > 0 &&
                        <div className="cc-section-error">
                            Outside of delivery range
                        </div>
                    }

                    <div className={`cc-section-input ${ deliveryAddressError.length > 0 ? "cc-section-input-error" : ""}`}>
                        <Autocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            onFocus={deliveryAddressFieldFocused}
                            onPlaceSelected={onPlaceSelected}
                            options={{
                                types: ["address"],
                                componentRestrictions: { country: "us" },
                            }}
                            placeholder="Enter your Delivery Address"
                        />
                    </div>
                    <div className="cc-section-input">
                        <input type="text" placeholder="apt, floor, suite, etc" onChange={(e) => setDeliveryAddress2(e.target.value)}></input>
                    </div>
                </div>

                <OrderSummary showSubmitBtn={true} />
            </td>
        </tr>
    )
}

export default CartCheckoutTableCell
