import { useState, useEffect, createContext } from "react"
import { axiosClient } from "./APIClient"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage["c9-user"] || null))
    const [token, setToken] = useState(JSON.parse(localStorage["c9-token"] || null))
    const [isOutletOwner, setIsOutletOwner] = useState(false)

    useEffect(() => {
        validateUserAndToken()
    }, [token])

    const validateUserAndToken = () => {
        // Check Non Existent User or Token
        if (user === null || token === null) {
            handleUserLogout()
            return false
        }
        
        // Check Token Expired
        if (token != null && token.expires_at != null) {
            const expireDate = new Date(token.expires_at)
            const dateNow = new Date()
      
            if (expireDate <= dateNow) {
                handleUserLogout()
                return false
            }
        }

        // Assign Authorization Access Token To API Client
        if (token != null && token.access_token != null) {
            axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
        }

        return true
    }

    const handleUserAuthHash = (userHash, tokenHash) => {
        // Process User
        if (userHash != null) {
            setUser(userHash)
            localStorage.setItem("c9-user", JSON.stringify(userHash))
        }else{
            setUser(null)
            localStorage.removeItem("c9-user")
        }

        // Process Token
        if (tokenHash != null) {
            setToken(tokenHash)
            localStorage.setItem("c9-token", JSON.stringify(tokenHash))
        }else{
            setToken(null)
            localStorage.removeItem("c9-token")
        }
    }

    const handleUserLogin = (res) => {
        handleUserAuthHash((res.user || null), (res.token || null))
    }

    const handleUserLogout = () => {
        handleUserAuthHash(null, null)
    }

    const getUserRoles = () => {
        let isAdmin = false
        let isDriver = false
        let isDispensary = false

        if (user != null && user.roles != null) {
            for (let i = 0; i < user.roles.length; i++) {
                const aRole = user.roles[i]
                if (aRole.slug != null) {
                    if (aRole.slug === "admin") {
                        isAdmin = true
                    }
                    if (aRole.slug === "driver") {
                        isDriver = true
                    }
                    if (aRole.slug === "outlet") {
                        isDispensary = true
                    }
                }
            }
        }

        return {isAdmin, isDriver, isDispensary}
    }

    const getUserRolesDisplay = () => {
        let userRoles = ""
        if (user != null && user.roles != null) {
            for (let i = 0; i < user.roles.length; i++) {
                const aRole = user.roles[i]
                if (aRole.slug != null) {
                    const aRoleSlug = aRole.slug === "admin" ? "Admin" : aRole.slug === "outlet" ? "Dispensary" : aRole.slug === "driver" ? "Driver" : "!Missing Role!"
                    const lastRole = i === user.roles.length - 1
                    userRoles += 
                    (i === 0 && user.roles.length === 1) ? aRoleSlug : 
                    (i === 0 && user.roles.length > 1) ? `${aRoleSlug} | ` : 
                    (i > 0 && user.roles.length > 2 && !lastRole) ? `${aRoleSlug} | ` :
                    lastRole ? aRoleSlug :
                    aRoleSlug
                }
            }
        }
        return userRoles
    }

    const getUserRouteAccess = (accessPath) => {
        let hasAccess = false
        if (user != null && user.roles != null) {
            for (let i = 0; i < user.roles.length; i++) {
                const aRole = user.roles[i]
                if (aRole.slug != null) {
                    const aRoleSlug = aRole.slug

                    if (accessPath.includes("/myorders/menu")) {
                        hasAccess = true
                        break
                    }else if (accessPath.includes("/myorders/orders")) {
                        hasAccess = aRoleSlug.includes("admin") || aRoleSlug.includes("driver") || aRoleSlug.includes("outlet")
                        break
                    }else if (accessPath.includes("/myorders/users")) {
                        hasAccess = aRoleSlug.includes("admin")
                        break
                    }else if (accessPath.includes("/myorders/outlets") && accessPath.includes("/inventory")) {
                        hasAccess = aRoleSlug.includes("admin") || (aRoleSlug.includes("outlet") && isOutletOwner)
                    }else if (accessPath.includes("/myorders/outlets")) {
                        hasAccess = aRoleSlug.includes("admin")
                        break
                    }else if (accessPath.includes("/myorders/controls")) {
                        hasAccess = aRoleSlug.includes("admin")
                        break
                    }
                }
            }
        }
        return hasAccess
    }

    const providerValue = {user, token, validateUserAndToken, handleUserLogin, handleUserLogout, getUserRoles, getUserRolesDisplay, getUserRouteAccess, isOutletOwner, setIsOutletOwner}
    return (
        <UserContext.Provider value={providerValue}>
            { children }
        </UserContext.Provider>
    )
}