import AdminLogo from "../Shared/AdminLogo"
import AdminTableCreateCell from "../Shared/AdminTableCreateCell"
import AdminOutletInventoryTableCell from "./AdminOutletInventoryTableCell"
import AdminOutletInventoryEditTableCell from "./AdminOutletInventoryEditTableCell"

import "../../Styles/AdminPageStyles/adminOutletInventoryTable.scss"

const AdminOutletInventoryTable = ({ mode, locations, inventory }) => {
    const viewMode = mode === "view"
    const createMode = mode === "create"
    const editMode = mode === "edit"

    const adminLogoText = viewMode === true ? `${inventory.outlet.name} Inventory` : createMode === true ? "Add Product" : editMode === true ? "Edit Product" : ""
    const backBtnPath = viewMode === true ? `/myorders/outlets/${inventory.outlet._id}` : (createMode === true || editMode === true) ? `/myorders/outlets/${inventory.outlet._id}/inventory` : `/myorders/outlets/${inventory.outlet._id}`
    
    
    let inventoryProducts = inventory.products || []
    inventoryProducts = inventoryProducts.sort((a, b) => { // Sort By Product Name Alphabetically

        const productNameA = a.productName ? a.productName : ""
        const productNameB = b.productName ? b.productName : ""

        return productNameA.localeCompare(productNameB)
    })

    return (
        <div>
            <AdminLogo logoText={adminLogoText} displayBackBtn={true} backBtnPath={backBtnPath} />

            <table className="admin-outlets-inventory-table">
                    <thead>
                        { viewMode &&
                            <AdminTableCreateCell createLocation={`/myorders/outlets/${inventory.outlet._id}/inventory/create`} />
                        }
                    </thead>
                    <tbody>
                        { (viewMode && inventoryProducts) && inventoryProducts.map(product => (
                            <AdminOutletInventoryTableCell product={product} locations={locations} inventory={inventory} />
                        )) }
                        { (createMode || editMode) &&
                            <AdminOutletInventoryEditTableCell mode={mode} />
                        }
                    </tbody>
            </table>
        </div>
    )
}

export default AdminOutletInventoryTable
