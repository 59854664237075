import { useState, createContext } from "react"

export const RoutesContext = createContext()

export const RoutesProvider = ({ children }) => {
    const [baseOrganization, setBaseOrganization] = useState(JSON.parse(localStorage["c9-org"] || "[]")) // Empty is Main (c9)

    const landingPaths = [
        "/", 
        "/area420"
    ]
    const aboutPaths = [
        "/about",
        "/area420/about"
    ]
    const dispensaryDetailPaths = [
        "/dispensary/:dispensaryid/:locationid", 
        "/area420/dispensary/:dispensaryid/:locationid"
    ]
    const dispensaryProductPaths = [
        "/dispensary/:dispensaryid/:locationid/:category",
        "/area420/dispensary/:dispensaryid/:locationid/:category"
    ]
    const cartPaths = [
        "/cart", 
        "/area420/cart"
    ]
    const checkoutPaths = [
        "/checkout", 
        "/area420/checkout"
    ]
    const orderConfirmationPaths = [
        "/order-confirmation",
        "/area420/order-confirmation"
    ]

    const handleSetBaseOrganization = (org) => {
        localStorage.setItem("c9-org", JSON.stringify(org))
        setBaseOrganization(org)
    }

    const getGoToRouteForOrganization = (urlPath = "") => {
        return baseOrganization != "" ? `/${baseOrganization}${urlPath}` : urlPath
    }

    const providerValue = {
        baseOrganization,
        handleSetBaseOrganization,
        landingPaths,
        aboutPaths,
        dispensaryDetailPaths,
        dispensaryProductPaths,
        cartPaths,
        checkoutPaths,
        orderConfirmationPaths,
        getGoToRouteForOrganization
    }
    return (
        <RoutesContext.Provider value={providerValue}>
            { children }
        </RoutesContext.Provider>
    )
}