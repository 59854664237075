import { useState, useEffect, useRef } from "react"
import { useParams, useHistory } from "react-router"
import { adminGetUsers, adminSyncOutletLocations, adminSyncOutletInventory, adminGetOutlet, adminAddOutlet, adminEditOutlet } from "../../Utils/APIManager"
import { phoneOnly } from "../../Utils/InputValidators"
import { stringToPhoneFormat, stringToPhoneDataFormat, dispensaryLocationFormat, capitalizeFirstLetter } from "../../Utils/StringFormatters"

const AdminOutletsEditTableCell = ({ mode }) => {
    let history = useHistory()
    const createMode = mode === "create"
    const editMode = mode === "edit"

    const { outletid } = useParams()
    const streetRef = useRef()
    const cityRef = useRef()
    const stateRef = useRef()
    const zipCodeRef = useRef()

    const [allUsers, setAllUsers] = useState([])
    const [outletRoleUsers, setOutletRoleUsers] = useState([])
    const [thirdPartyFromResponse, setThirdPartyFromResponse] = useState("none")

    const [name, setName] = useState("")
    const [logo, setLogo] = useState(null)
    const [locations, setLocations] = useState([])
    const [phoneNumber, setPhoneNumber] = useState("")
    const [businessLicenseNumber, setBusinessLicenseNumber] = useState("")
    const [outletType, setOutletType] = useState("none")
    const [published, setPublished] = useState("none")
    const [users, setUsers] = useState([])
    const [outletOwnerUser, setOutletOwnerUser] = useState("none")
    const [thirdParty, setThirdParty] = useState("none")

    useEffect(() => {
        getUsers()

        if (editMode) {
            getOutlet()
        }
    }, [])

    const getOutlet = async () => {
        const admin_get_outlet_response = await adminGetOutlet(outletid)
        if (admin_get_outlet_response.data != null) {
            const outlet = admin_get_outlet_response.data.outlet
            setName(outlet.name)
            setLogo(outlet.logo)
            setPhoneNumber(stringToPhoneFormat(outlet.phone_number, false))
            setOutletType(outlet.outlet_type != null ? outlet.outlet_type : "none")
            setPublished(outlet.published === true ? "true" : outlet.published === false ? "false" : "none")
            setBusinessLicenseNumber(outlet.business_number)
            setOutletOwnerUser(outlet.owner != null ? outlet.owner._id : "none")
            setThirdPartyFromResponse(outlet.third_party) // State Triggers Managing Specifics UI only after initial save of a PoS selection
            setThirdParty(outlet.third_party)

            let outlet_locations = []
            for (let i = 0; i < outlet.locations.length; i++) {
                outlet_locations.push(outlet.locations[i])
            }
            setLocations(outlet_locations)

            let outlet_users = []
            for (let i = 0; i < outlet.budtenders.length; i++) {
                outlet_users.push(outlet.budtenders[i]._id)
            }
            setUsers(outlet_users)
        }
    }

    const getUsers = async () => {
        const users_response = await adminGetUsers()
        if (users_response.data != null) {
            setAllUsers(users_response.data.users)

            let outletUsers = []
            for (let i = 0; i < users_response.data.users.length; i++) {
                const user = users_response.data.users[i]
                const roles = user.roles

                for (let j = 0; j < roles.length; j++) {
                    const aRole = roles[j]
                    if (aRole.slug === "outlet") {
                        outletUsers.push(user)
                    }
                }
            }

            setOutletRoleUsers(outletUsers)
        }
    }

    const inventoryBtnClicked = () => {
        history.push(`/myorders/outlets/${outletid}/inventory`)
    }

    const transactionsBtnClicked = () => {
        history.push(`/myorders/outlets/${outletid}/transactions`)
    }

    const credentialsBtnClicked = () => {
        history.push(`/myorders/outlets/${outletid}/credentials`)
    }

    const syncLocationsBtnClicked = async () => {
        const sync_locations_response = await adminSyncOutletLocations(outletid)
        if (sync_locations_response.data != null) {
            alert("Outlet Locations Have Been Successfully Synced, Reloading Locations...")
            history.go(0)
        }
    }

    const syncInventoryBtnClicked = async () => {
        const sync_inventory_response = await adminSyncOutletInventory(outletid)
        if (sync_inventory_response.data != null) {
            alert("Outlet Inventory & Locations Has Been Successfully Synced, Reloading...")
            history.go(0)
        }
    }

    const handleOutletTypeSelectChange = (e) => {
        const value = e.target.value
        setOutletType(value === "none" ? null : value) 
    }

    const handlePublishSelectChange = (e) => {
        const value = e.target.value
        setPublished(value === "false" ? false : value === "true" ? true : value === "none" ? null : false)
    }

    const handleThirdPartySelectChange = (e) => {
        const value = e.target.value
        setThirdParty(value)
    }

    const addLocationClicked = () => {
        const streetValue = streetRef.current.value
        const cityValue = cityRef.current.value
        const stateValue = stateRef.current.value
        const zipCodeValue = zipCodeRef.current.value

        if (streetValue.trim().length === 0) {
            alert("Street Address is required when adding a Location")
            return
        }

        if (cityValue.trim().length === 0) {
            alert("City is required when adding a Location")
            return
        }

        if (stateValue.trim().length === 0) {
            alert("State is required when adding a Location")
            return
        }

        if (zipCodeValue.trim().length === 0) {
            alert("Zip Code is required when adding a Location")
            return
        }

        const newLocation = {
            "city": cityValue,
            "state": stateValue,
            "street": streetValue,
            "zipcode": zipCodeValue,
            "locationId": "0"
        }

        setLocations(oldLocations => [...oldLocations, newLocation])

        streetRef.current.value = ""
        cityRef.current.value = ""
        stateRef.current.value = ""
        zipCodeRef.current.value = ""
    }

    const removeLocationClicked = (locationIndex) => {
        let updatedLocations = [...locations]
        updatedLocations.splice(locationIndex, 1)
        setLocations(updatedLocations)
    }

    const handleUsersSelectChange = (e) => {
        var options = e.target.options
        var value = []
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value)
          }
        }
        setUsers(value)
    }

    const handleOutletOwnerSelectChange = (e) => {
        const value = e.target.value
        setOutletOwnerUser(value === "none" ? null : value)
    }

    const outletSubmitted = async () => {

        if (name.trim().length === 0) {
            alert("Name is Required")
            return false
        }

        if (logo === null) {
            alert("Logo is Required")
            return false
        }

        if (phoneNumber.trim().length === 0) {
            alert("Phone Number is Required")
            return false
        }

        if (outletType === null || outletType === "none") {
            alert("Outlet Type selection is Required")
            return false
        }

        if (published === null) {
            alert("Published/Unpublished is Required")
            return false
        }

        let sendData = {
            "outlet": {
                "name": name,
                "logo": logo,
                "phone_number": stringToPhoneDataFormat(phoneNumber),
                "locations": locations,
                "outlet_type": outletType,
                "owner": (outletOwnerUser === null || outletOwnerUser === "none") ? null : outletOwnerUser,
                "published": published,
                "business_number": businessLicenseNumber,
                "budtenders": users,
                "third_party": thirdParty
            }
        }

        if (createMode) {
            const outlet_response = await adminAddOutlet(sendData)
            if (outlet_response.data != null) {
                history.push("/myorders/outlets")
            }
        }

        if (editMode) {
            const outlet_response = await adminEditOutlet(outletid, sendData)
            if (outlet_response.data != null) {
                history.push("/myorders/outlets")
            }
        }
    }

    const userNameLabel = (user) => {
        if (user.first_name && user.last_name) {
            return `${user.first_name} ${user.last_name}`
        }else if (user.email) {
            return user.email
        }else{
            return `!Missing Name!`
        }
    }

    return (
        <tr>
            <td className="admin-outlets-edit-table-cell">
                <div>
                    <label>Name:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div>
                    <label>Logo File:</label>
                    <input type="text" value={logo} onChange={(e) => setLogo(e.target.value)}></input>
                </div>
                <div>
                    <label>Phone Number:</label>
                    <input type="text" defaultValue={phoneNumber} inputMode="numeric" onKeyUp={(e) => phoneOnly(e, setPhoneNumber)}></input>
                </div>
                <div>
                    <label>Outlet Type:</label>
                    <select value={outletType} onChange={handleOutletTypeSelectChange}>
                        <option value={"none"}>Choose option</option>
                        <option value="retail-rec">Retail - Recreational</option>
                        <option value="retail-med">Retail - Medical</option>
                        <option value="wholesale">Wholesale</option>
                    </select>
                </div>
                <div>
                    <label>Published / Unpublished:</label>
                    <select value={published} onChange={handlePublishSelectChange}>
                        <option value={"none"}>Choose Type</option>
                        <option value={"true"}>Published</option>
                        <option value={"false"}>Unpublished</option>
                    </select>
                </div>
                <div>
                    <label>Business License Number:</label>
                    <input type="text" value={businessLicenseNumber} onChange={(e) => setBusinessLicenseNumber(e.target.value)}></input>
                </div>
                { editMode && 
                    <div>
                        <label>{"Transactions & Balance:"}</label>
                        <button className="manage-btn" onClick={() => transactionsBtnClicked()}>View / Manage</button>
                    </div>
                }
                { editMode &&
                    <div>
                        <label>{"Inventory:"}</label>
                        <button className="manage-btn" onClick={() => inventoryBtnClicked()}>View / Manage</button>
                    </div>
                }
                { editMode &&
                    <div>
                        <label>Third Party PoS:</label>
                        <span style={{"fontSize": "14px"}}>• Note: selecting/changing a third party PoS requires you to save the outlet before it allows you to manage specific controls</span>
                        <select value={thirdParty} onChange={handleThirdPartySelectChange}>
                            <option value={"none"}>None</option>
                            <option value="flowhub">Flowhub</option>
                            <option value="greenbits">Greenbits</option>
                            <option value="biotrack">Biotrack</option>
                        </select>
                    </div>
                }
                { (editMode && thirdPartyFromResponse != "none" && thirdPartyFromResponse === thirdParty) && 
                    <div>
                        <label>Manage PoS ({capitalizeFirstLetter(thirdParty)}):</label>
                        <button className="manage-btn" onClick={() => credentialsBtnClicked()}>View / Change Credentials</button>
                        <button className="manage-btn" onClick={() => syncInventoryBtnClicked()}>{"Sync Inventory & Locations"}</button>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Locations:</label>
                        <div className="outlet-locations-container">
                            { locations.length === 0 &&
                                <span>No Available Locations</span>
                            }
                            { locations.map((location, index) => (
                                <span>
                                    <button onClick={() => removeLocationClicked(index)}>X</button>
                                    {index + 1}). { dispensaryLocationFormat(location) }
                                </span>
                            )) }
                        </div>
                        
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Add Location:</label>
                        <div className="outlet-add-location-container">
                            <input ref={streetRef} type="text" placeholder={"Street Address"}></input>
                            <div className="oal-secondary">
                                <input ref={cityRef} type="text" placeholder={"City"}></input>
                                <input ref={stateRef} type="text" placeholder={"State"}></input>
                                <input ref={zipCodeRef} type="text" placeholder={"Zipcode"}></input>
                            </div>
                            <button className="oal-add-location" onClick={() => addLocationClicked()}>Add</button>
                        </div>
                    </div>
                }
                <div>
                    <label>Assigned Users:</label>
                    <select className="users-select" value={users} multiple={true} onChange={handleUsersSelectChange}>
                        { allUsers.map(user => (
                            <option value={user._id}>{userNameLabel(user)}</option>
                        )) }
                    </select>
                </div>
                { editMode &&
                    <div>
                        <label>Outlet Owner:</label>
                        <select value={outletOwnerUser} onChange={handleOutletOwnerSelectChange}>
                            <option value={"none"}>None</option>
                            { outletRoleUsers.map(user => (
                                <option value={user._id}>{userNameLabel(user)}</option>
                            )) }
                        </select>
                    </div>
                }
                <div>
                    <button onClick={() => outletSubmitted()}>Save</button>
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletsEditTableCell
