import { useContext } from "react"
import { CartContext } from "../Utils/CartManager"
import CartOrderDetailsTableCell from "./CartOrderDetailsTableCell"

import "../Styles/CartPageStyles/cartOrderDetailsTable.scss"

const CartOrderDetailsTable = ({ mode }) => {
    const { cartItems } = useContext(CartContext)

    return (
        <table className="cart-order-details-table">
            <thead>
                <tr>
                    <td>
                        <label>Order Details</label>
                    </td>
                </tr>
            </thead>
            
            <tbody>
                { cartItems.map((cartItem) => (
                    <CartOrderDetailsTableCell cartItem={cartItem} mode={mode} />
                )) }
            </tbody>
        </table>
    )
}

export default CartOrderDetailsTable
