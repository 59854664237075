import AdminLogo from "../Shared/AdminLogo"
import AdminTableCreateCell from "../Shared/AdminTableCreateCell"
import AdminOutletsTableCell from "./AdminOutletsTableCell"
import AdminOutletsEditTableCell from "./AdminOutletsEditTableCell"

import "../../Styles/AdminPageStyles/adminOutletsTable.scss"

const AdminOutletsTable = ({ mode, outlets }) => {
    const viewMode = mode === "view"
    const createMode = mode === "create"
    const editMode = mode === "edit"

    const adminLogoText = viewMode === true ? "Outlets" : createMode === true ? "Add Outlet" : editMode === true ? "Edit Outlet" : ""
    const backBtnPath = viewMode === true ? "/myorders/menu" : (createMode === true || editMode === true) ? "/myorders/outlets" : "/myorders/menu"
    return (
        <div>
            <AdminLogo logoText={adminLogoText} displayBackBtn={true} backBtnPath={backBtnPath} />

            <table className="admin-outlets-table">
                    <thead>
                        { viewMode &&
                            <AdminTableCreateCell createLocation={"/myorders/outlets/create"} />
                        }
                    </thead>
                    <tbody>
                        { viewMode && outlets.map(outlet => (
                            <AdminOutletsTableCell outlet={outlet} />
                        )) }
                        { (createMode || editMode) &&
                            <AdminOutletsEditTableCell mode={mode} />
                        }
                    </tbody>
            </table>
        </div>
    )
}

export default AdminOutletsTable
