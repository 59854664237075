import React from 'react'
import ReactDOM from 'react-dom'
import "./components/Styles/main.scss"
import App from './App'
import reportWebVitals from './reportWebVitals'
import HttpsRedirect from "react-https-redirect"


const AppComponent = () => {
  const localDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  if (localDev) {
    return <App/>
  }
  return <HttpsRedirect><App/></HttpsRedirect>
}

ReactDOM.render(
  <React.StrictMode>
    <AppComponent />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()