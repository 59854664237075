import DispensariesDetailTable from "../components/DispensariesDetailPage/DispensariesDetailTable"

import "../components/Styles/DispensariesDetailPageStyles/dispensariesDetailPage.scss"

const DispensariesDetailPage = () => {
    return (
        <div className="dispensaries-detail-page-container">
            <DispensariesDetailTable />
        </div>
    )
}

export default DispensariesDetailPage
