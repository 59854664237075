import CartCheckoutTableHeadCell from "./CartCheckoutTableHeadCell"
import CartCheckoutTableCell from "./CartCheckoutTableCell"

import "../Styles/CartPageStyles/cartCheckoutTable.scss"

const CartCheckoutTable = ({ props }) => {

    return (
        <table className="cart-checkout-table">
            <thead>
                <CartCheckoutTableHeadCell />
            </thead>

            <tbody>
                <CartCheckoutTableCell props={props} />
            </tbody>
        </table>
    )
}

export default CartCheckoutTable
