import { useState, useEffect } from "react"
import { adminGetOutlets } from "../../components/Utils/APIManager"
import AdminOutletsTable from "../../components/AdminPages/AdminOutletsPage/AdminOutletsTable"

import "../../components/Styles/AdminPageStyles/adminOutletsPage.scss"

const AdminOutletsPage = ({ mode }) => {
    const viewMode = mode === "view"

    const [outlets, setOutlets] = useState([])

    useEffect(() => {
        if (viewMode) {
            getOutlets()
        }
    }, [])

    const getOutlets = async () => {
        const outlets_response = await adminGetOutlets()
        if (outlets_response.data != null) {
            setOutlets(outlets_response.data.outlets)
        }
    }

    return (
        <div className="admin-outlets-page-container">
            <AdminOutletsTable mode={mode} outlets={outlets} />
        </div>
    )
}

export default AdminOutletsPage