import { useState, useEffect, useContext } from "react"
import { useHistory, Link } from "react-router-dom"
import { userLogin } from "../../components/Utils/APIManager"
import { UserContext } from "../../components/Utils/UserManager"
import Logo from "../../components/Images/logo.png"

import "../../components/Styles/AdminPageStyles/adminLandingPage.scss"

const AdminLandingPage = () => {
    const { token, validateUserAndToken, handleUserLogin } = useContext(UserContext)
    let history = useHistory()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        // Check User Already Logged In, Redirect to Menu
        if (validateUserAndToken()) {
            history.push("/myorders/menu")     
        }
    }, [])

    const loginFormSubmitted = async (e) => {
        e.preventDefault()

        if (email.trim().length === 0) {
            alert("Email is required")
            return false
        }

        if (password.trim().length === 0) {
            alert("Password is required")
            return false
        }

        const sendData = {
            email,
            password
        }

        const user_login_response = await userLogin(sendData)
        if (user_login_response.data != null) {
            
            // Check User Suspended
            const login_user = user_login_response.data.user || null
            if (login_user != null && login_user.suspended) {
                alert("Your account access is revoked. Please contact support.")
                return false
            }

            handleUserLogin(user_login_response.data)
            history.push("/myorders/menu")
        }
    }

    return (
        <div className="admin-landing-page-container">
            
            <div className="alp-logo-container">
                <img src={Logo}></img>
            </div>

            <form onSubmit={loginFormSubmitted}>
                <div className="alp-fields-container">
                    <input placeholder="email" name="email" onChange={(e) => setEmail(e.target.value)}></input>
                    <input placeholder="password" type="password" name="password" onChange={(e) => setPassword(e.target.value)}></input>
                    <button type="submit">Log In</button>
                </div>
            </form>

            <div className="alp-footer-container">
                <Link to={"/myorders/help"}>Forgot / Change Password</Link>
            </div>

        </div>
    )
}

export default AdminLandingPage
