import AdminLogo from "../Shared/AdminLogo"
import AdminTableCreateCell from "../Shared/AdminTableCreateCell"
import AdminUsersTableCell from "./AdminUsersTableCell"
import AdminUsersEditTableCell from "./AdminUsersEditTableCell"

import "../../Styles/AdminPageStyles/adminUsersTable.scss"

const AdminUsersTable = ({ mode, users }) => {
    const viewMode = mode === "view"
    const createMode = mode === "create"
    const editMode = mode === "edit"

    const adminLogoText = viewMode === true ? "Users" : createMode === true ? "Add User" : editMode === true ? "Edit User" : ""
    const backBtnPath = viewMode === true ? "/myorders/menu" : (createMode === true || editMode === true) ? "/myorders/users" : "/myorders/menu"
    return (
        <div>
            <AdminLogo logoText={adminLogoText} displayBackBtn={true} backBtnPath={backBtnPath} />

            <table className="admin-users-table">
                    <thead>
                        { viewMode && 
                            <AdminTableCreateCell createLocation={"/myorders/users/create"} />
                        }
                    </thead>
                    <tbody>
                        { viewMode && users.map(user => (
                            <AdminUsersTableCell user={user} />
                        )) }
                        { (createMode || editMode) &&
                            <AdminUsersEditTableCell mode={mode} />
                        }
                    </tbody>
            </table>
        </div>
    )
}

export default AdminUsersTable
