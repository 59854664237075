import { useContext } from "react"
import { useHistory } from "react-router"
import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"
import { productSpeciesDisplay, productGramsDisplay, productPriceDisplay } from "../Utils/StringFormatters"
import AWSImage from "../Shared/AWSImage"
import LogoSmall from "../Images/logo-small.png"

import "../Styles/CartPageStyles/cartOrderDetailsTableCell.scss"

const CartOrderDetailsTableCell = ({ cartItem, mode }) => {
    let history = useHistory()
    const { getGoToRouteForOrganization } = useContext(RoutesContext)
    const { cartDispensaryID, cartLocationID, removeItemFromCart } = useContext(CartContext)

    const editBtnClicked = () => {
        history.push(getGoToRouteForOrganization(`/dispensary/${cartDispensaryID}/${cartLocationID}/${cartItem.product?.category}`))
    }

    const productName = () => {
        return cartItem.product?.productName || ""
    }

    const productSpecies = () => {
        const containsSpecies = cartItem.product?.speciesName != null
        return containsSpecies ? productSpeciesDisplay(cartItem.product?.speciesName) : cartItem.product?.brand
    }

    const productGramPrice = () => {
        let displayFormat = ""
        const containsGramAmount = cartItem.gramAmount != null
        const price = cartItem.productPrice * cartItem.quantity
        
        if (containsGramAmount) {
            displayFormat = `${productGramsDisplay(cartItem.gramAmount)} (Qty: ${cartItem.quantity}) = ${productPriceDisplay(price)}`
        }else{
            displayFormat = `(Qty: ${cartItem.quantity}) = ${productPriceDisplay(price)}`
        }

        return displayFormat
    }

    return (
        <tr className="cart-order-details-table-cell" key={`${cartItem.product?.productId}_cod`}>
            <td>
                <div className="cod-info-section">
                    <div>
                        <label>{ productName() }</label>
                        <label>{ productSpecies() }</label>
                        <label>{ productGramPrice() }</label>
                    </div>
                    { (cartItem.product?.images === null || cartItem.product?.images.length === 0) &&
                        <div>
                            <img src={cartItem.product?.productPictureURL || LogoSmall}></img>
                        </div>
                    }
                    { (cartItem.product?.images != null && cartItem.product?.images.length > 0) &&
                        <div className="dp-images-container">
                            { cartItem.product?.images.map(image => (
                                <AWSImage fileKey={image.path} enableImageViewer={true}></AWSImage>
                            )) }
                        </div>
                    }
                </div>

                { mode != "checkout" &&
                    <div className="cod-actions-section">
                        <button onClick={() => {editBtnClicked()}}>Edit</button>
                        <button onClick={() => {removeItemFromCart(cartItem, true)}}>Delete</button>
                    </div>
                }
                <hr></hr>
            </td>
        </tr>
    )
}

export default CartOrderDetailsTableCell
