import DispensariesProductsTable from "../components/DispensariesProductsPage/DispensariesProductsTable"

import "../components/Styles/DispensariesProductsPageStyles/dispensariesProductsPage.scss"

const DispensariesProductsPage = () => {
    return (
        <div className="dispensaries-products-page-container">
            <DispensariesProductsTable />
        </div>
    )
}

export default DispensariesProductsPage
