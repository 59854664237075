import { useState, useEffect } from "react"
import { awsGetObject } from "../Utils/awsHandler"
import VisibilitySensor from "react-visibility-sensor"
import AWSImageViewer from "./AWSImageViewer"

import "../Styles/awsImage.scss"

const AWSImage = ({ fileKey, imageOnClick, fallbackImage, imageURL, enableImageViewer }) => {

    const [imageSrc, setImageSrc] = useState(null)
    const [loadingClassName, setLoadingClassName] = useState("img-loading")
    const [visible, setVisible] = useState(false)

    const [showImageViewer, setShowImageViewer] = useState(false)

    useEffect(async () => {
        if (visible && imageSrc === null) {
            // AWS File Dir/Key Has Been Provided as Prop
            if (fileKey != null) {
                getImage(fileKey)
            }else if (imageURL != null) {
                setImageSrc(imageURL)
                setLoadingClassName("")
            }
        }
    }, [visible, fileKey])

    const getImage = async (key) => {
        let successfulGET = false

        if (key != null) {

             awsGetObject(key).then(res => {

                if (res != null && res.Body != null) {
                    const base64 = res.Body.toString('base64')
                    setImageSrc(`data:image;base64,${base64}`)

                    setLoadingClassName("")

                    successfulGET = true
                }

            }).catch(error => {
                throw(error)
            })
        }

        if (fallbackImage != null && !successfulGET) {
            setImageSrc(fallbackImage)
            setLoadingClassName("")
        }
    }

    const presentImageViewer = () => {
        setShowImageViewer(true)
    }

    const hideImageViewer = () => {
        setShowImageViewer(false)
    }

    return (
        <VisibilitySensor onChange={(isVisible) => setVisible(isVisible)} partialVisibility={true}>
            <div className="awsimg-container">
                <img className={loadingClassName} src={imageSrc} onClick={(enableImageViewer != null && enableImageViewer === true) ? presentImageViewer : imageOnClick} loading="lazy"></img>

                { (enableImageViewer != null && enableImageViewer === true) &&
                    <AWSImageViewer handleClose={hideImageViewer} show={showImageViewer} imageSrc={imageSrc}></AWSImageViewer>
                }
                
            </div>
        </VisibilitySensor>
    )
}

export default AWSImage
