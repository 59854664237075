import { useState, useEffect, createContext } from "react"
import { dollarsToCents } from "./StringFormatters"

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
    
    const [cartItems, setCartItems] = useState(JSON.parse(localStorage["c9-cart-items"] || "[]"))
    const [cartDispensary, setCartDispensary] = useState(JSON.parse(localStorage["c9-cart-dispensary"] || null))
    const [cartDispensaryID, setCartDispensaryID] = useState(JSON.parse(localStorage["c9-cart-dispensary-id"] || null))
    const [cartLocationID, setCartLocationID] = useState(JSON.parse(localStorage["c9-cart-location"] || null))
    const [cartItemCount, setCartItemCount] = useState(0)
    const [cartDeliveryFee, setCartDeliveryFee] = useState(null)



    useEffect(() => {
        updateCartItemCount()
        localStorage.setItem("c9-cart-items", JSON.stringify(cartItems))

        // Reset Cart Location upon Empty
        if (cartItems.length === 0) {
            localStorage.removeItem("c9-cart-dispensary")
            setCartDispensary(null)

            localStorage.removeItem("c9-cart-dispensary-id")
            setCartDispensaryID(null)

            localStorage.removeItem("c9-cart-location")
            setCartLocationID(null)
        }
    }, [cartItems])

    const resetCart = () => {
        setCartItems([])   
    }

    const getCartItem = (id) => {
        let cartItem = {id: id, quantity: 0}
        for (let i = 0; i < cartItems.length; i++) {
            const item = cartItems[i]
            if (item.id === id) {
                cartItem = item
                break
            }
        }
        return cartItem
    }

    const addItemToCart = (item, dispensary, dispensaryID, locationID) => {
        // Check if Dispensary Location is the same against other cart items
        let locationAllowed = handleCartLocation(dispensary, dispensaryID, locationID)
        if (!locationAllowed) {
            alert("You currently have items in your cart belonging to another dispensary location. You may only order from 1 dispensary at a time.")
            return
        }

        if (item.quantity === 1) {
            // Add Item To Cart
            const updatedCartItems = [...cartItems, item]
            setCartItems(updatedCartItems)     
        }else{
            // Update Item Quantity In Cart (quantity > 1)
            updateItemInCart(item)
        }
    }

    const removeItemFromCart = (item, deleted) => {
        if (item.quantity <= 0 || deleted) {
            // Remove Item From Cart
            let updatedCartItems = cartItems.filter(cartItem => cartItem.id !== item.id)
            setCartItems(updatedCartItems)
        }else{
            // Update Item Quantity In Cart (quantity > 0)
            updateItemInCart(item)
        }
    }

    const updateItemInCart = (item) => {
        setCartItems(
            cartItems.map((cartItem) => {
                return cartItem.id === item.id ? item : cartItem
            })
        )
    }

    const updateCartItemCount = () => {
        let updatedItemCount = 0
        for (let i = 0; i < cartItems.length; i++) {
            const item = cartItems[i]
            updatedItemCount = updatedItemCount + item.quantity
        }
        setCartItemCount(updatedItemCount)
    }

    const handleCartLocation = (dispensary, dispensaryID, locationID) => {
        let locationAllowed = true

        if (cartLocationID === null && cartDispensaryID === null) {
            setCartDispensary(dispensary)
            localStorage.setItem("c9-cart-dispensary", JSON.stringify(dispensary))

            setCartDispensaryID(dispensaryID)
            localStorage.setItem("c9-cart-dispensary-id", JSON.stringify(dispensaryID))

            setCartLocationID(locationID)
            localStorage.setItem("c9-cart-location", JSON.stringify(locationID))
        }else if ((cartLocationID != null && locationID != cartLocationID) && (cartDispensaryID != null && dispensaryID != cartDispensaryID)) {
            locationAllowed = false
        }

        return locationAllowed
    }

    const checkCartLocationAllowed = (locationID) => {
        return (cartLocationID != null && locationID != cartLocationID) ? false : true
    }

    const calculateCartDeliveryFee = (milesDistance, baseOrganization) => { // Delivery Fee in Cents
        const area420Org = baseOrganization === "area420"
        
        if (!area420Org) {
            if (milesDistance < 3) {
                setCartDeliveryFee(1600)
            }
            if (milesDistance >= 3 && milesDistance < 5) {
                setCartDeliveryFee(1600)
            }
            if (milesDistance >= 5 && milesDistance < 10) {
                setCartDeliveryFee(1600)
            }
            if (milesDistance >= 10) {
                setCartDeliveryFee(1600)
            }
        }

        if (area420Org) {
            const tenPercentPerMileFee = parseFloat(milesDistance * 0.10)
            const deliverFee = dollarsToCents(parseFloat(milesDistance + tenPercentPerMileFee))
            setCartDeliveryFee(deliverFee)
        }
    }

    const getOrderTotalInCents = () => {
        let totalInCents = 0
        for (let i = 0; i < cartItems.length; i++) {
            const item = cartItems[i]
            if (item.quantity != null && item.productPrice != null) {
                totalInCents += (item.productPrice * item.quantity)
            }
        }
        return totalInCents
    }

    const providerValue = {cartItems, cartItemCount, cartDispensary, cartDispensaryID, cartLocationID, setCartItems, getCartItem, addItemToCart, removeItemFromCart, resetCart, checkCartLocationAllowed, cartDeliveryFee, calculateCartDeliveryFee, getOrderTotalInCents}
    return (
        <CartContext.Provider value={providerValue}>
            { children }
        </CartContext.Provider>
    )
}
