import { useHistory } from "react-router-dom"
import LogoSmall from "../../Images/logo-small.png"

import "../../Styles/AdminPageStyles/adminLogo.scss"

const AdminLogo = ({ logoText, displayBackBtn, backBtnPath}) => {
    let history = useHistory()

    return (
        <div className="admin-logo-container">
            <img src={LogoSmall}></img>
            <label>{logoText}</label>
            { displayBackBtn &&
                <button onClick={() => history.push(backBtnPath)}>{"<"}</button>
            }
        </div>
    )
}

export default AdminLogo
