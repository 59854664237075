import { useContext } from "react"
import { CartContext } from "../Utils/CartManager"
import { productPriceDisplay } from "../Utils/StringFormatters"

const OrderSummary = ({ showSubmitBtn }) => {
    const { cartItems, cartDeliveryFee } = useContext(CartContext)

    const determineCartValue = (orderTotalMode) => {
        let cartValue = 0
        for (let i = 0; i < cartItems.length; i++) {
            const anItem = cartItems[i]
            if (anItem.productPrice && anItem.quantity) {
                cartValue += (anItem.productPrice * anItem.quantity)
            }
        }

        return orderTotalMode ? productPriceDisplay(parseFloat(cartValue) + parseFloat(cartDeliveryFee)) : productPriceDisplay(parseFloat(cartValue))
    }

    return (
        <div className="cc-section">
            <label className="cc-section-label">Order Summary</label>
            <label className="cc-section-sum-label">Cart Value <span>{ determineCartValue(false) }</span></label>
            { cartDeliveryFee != null &&
                <label className="cc-section-sum-label">Delivery Fee (incl 1.37 state, city fees) <span>{ productPriceDisplay(cartDeliveryFee) }</span></label>
            }
            { cartDeliveryFee != null &&
                <label className="cc-section-sum-label">Order Total <span>{ determineCartValue(true) }</span></label>
            }

            { showSubmitBtn === true &&
                <div>
                    <button type="submit">Checkout</button>
                </div>
            }
        </div>
    )
}

export default OrderSummary
