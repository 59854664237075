import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { UserContext } from "../../components/Utils/UserManager"
import AdminLogo from "../../components/AdminPages/Shared/AdminLogo"

import "../../components/Styles/AdminPageStyles/adminMenuPage.scss"
import { adminGetUserOutlet } from "../../components/Utils/APIManager"

const AdminMenuPage = () => {
    const { handleUserLogout, getUserRolesDisplay, getUserRouteAccess, getUserRoles, isOutletOwner, setIsOutletOwner } = useContext(UserContext)

    const [ownerOutlet, setOwnerOutlet] = useState(null)

    useEffect(() => {
        
        // Determine if outlet user is an owner of an Outlet (gives different route access)
        const userRoles = getUserRoles()
        if (userRoles.isDispensary === true) {
            getUserOutlet()
        }
    }, [])

    const getUserOutlet = async () => {
        const user_outlet_response = await adminGetUserOutlet()
        if (user_outlet_response.data != null) {
            setIsOutletOwner(true)
            setOwnerOutlet(user_outlet_response.data.outlet)
        }
    }

    const logoutClicked = async (e) => {
        e.preventDefault()
        handleUserLogout()
        return false
    }

    const welcomeMenuText = () => {
        return `Welcome, ${getUserRolesDisplay()}`
    }

    const ordersPath = getUserRouteAccess("/myorders/orders") ? "/myorders/orders" : false
    const usersPath = getUserRouteAccess("/myorders/users") ? "/myorders/users" : false
    const outletsPath = getUserRouteAccess("/myorders/outlets") ? "/myorders/outlets" : false
    const controlsPath = getUserRouteAccess("/myorders/controls") ? "/myorders/controls" : false

    return (
        <div className="admin-menu-page-container">
            <AdminLogo logoText={welcomeMenuText()} displayBackBtn={false} />

            <div className="amp-option-container">

                { ordersPath && <Link to={ordersPath}>My Orders</Link> }
                { usersPath && <Link to={usersPath}>Users</Link> }
                { outletsPath && <Link to={outletsPath}>Outlets</Link> }
                { controlsPath && <Link to={controlsPath}>Controls</Link> }

                { (isOutletOwner && ownerOutlet != null) && <Link to={`/myorders/outlets/${ownerOutlet._id}/inventory`}>My Inventory</Link> }
                
                <a onClick={logoutClicked}>Logout</a>
            </div>
        </div>
    )
}

export default AdminMenuPage
