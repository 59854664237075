import { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router"
import { adminGetUserRoles, adminGetUser, adminAddUser, adminEditUser, adminSuspendUserAccess, adminUnsuspendUserAccess } from "../../Utils/APIManager"
import { phoneOnly } from "../../Utils/InputValidators"
import { stringToPhoneFormat, stringToPhoneDataFormat } from "../../Utils/StringFormatters"

const AdminUsersEditTableCell = ({ mode }) => {
    let history = useHistory()
    const createMode = mode === "create"
    const editMode = mode === "edit"

    const { userid } = useParams()

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [tempPassword, setTempPassword] = useState("")
    const [userType, setUserType] = useState([])
    const [userSuspended, setUserSuspended] = useState(null)
    const [userRoles, setUserRoles] = useState([])

    const [businessName, setBusinessName] = useState(null)
    const [businessLicenseNumber, setBusinessLicenseNumber] = useState(null)
    const [businessAddress, setBusinessAddress] = useState(null)

    const [driverLicenseNumber, setDriverLicenseNumber] = useState(null)
    const [driverBadgeNumber, setDriverBadgeNumber] = useState(null)
    const [driverCarMake, setDriverCarMake] = useState(null)
    const [driverCarModel, setDriverCarModel] = useState(null)
    const [driverCarPlate, setDriverCarPlate] = useState(null)

    useEffect(() => {
        if (createMode || editMode) {
            getUserRoles()

            if (editMode) {
                getUser()
            }
        }
    }, [])

    const getUser = async () => {
        const admin_get_user_response = await adminGetUser(userid)
        if (admin_get_user_response.data != null) {
            const user = admin_get_user_response.data.user
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
            setPhoneNumber(stringToPhoneFormat(user.phone_number, false))
            setUserSuspended(user.suspended)
            let user_roles_value = []
            for (let i = 0; i < user.roles.length; i++) {
                user_roles_value.push(user.roles[i]._id)
            }
            setUserType(user_roles_value)

            setDriverLicenseNumber(user.driver_license_number)
            setDriverBadgeNumber(user.driver_badge_number)
            if (user.car != null) {
                setDriverCarMake(user.car.make)
                setDriverCarModel(user.car.model)
                setDriverCarPlate(user.car.plate)
            }

            setBusinessLicenseNumber(user.business_license_number)
            setBusinessName(user.business_name)
            setBusinessAddress(user.business_address)
        }
    }

    const getUserRoles = async () => {
        const admin_user_roles_response = await adminGetUserRoles()
        if (admin_user_roles_response.data != null) {
            setUserRoles(admin_user_roles_response.data.roles)
        }
    }

    const userSubmitted = async () => {
        if (firstName.trim().length === 0) {
            alert("First Name is Required")
            return
        }
        if (lastName.trim().length === 0) {
            alert("Last Name is Required")
            return
        }
        if (email.trim().length === 0) {
            alert("Email is Required")
            return
        }
        if (phoneNumber.trim().length === 0) {
            alert("Phone Number is Required")
            return
        }
        if (createMode && tempPassword.trim().length === 0) {
            alert("Temp Password is Required")
            return
        }
        if (userType.length === 0) {
            alert("User Type is Required")
            return
        }

        let sendData = {
            "user": {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "phone_number": stringToPhoneDataFormat(phoneNumber),
                "roles": userType,
                "driver_license_number": driverLicenseNumber,
                "driver_badge_number": driverBadgeNumber,
                "car": {
                    "make": driverCarMake,
                    "model": driverCarModel,
                    "plate": driverCarPlate
                },
                "business_license_number": businessLicenseNumber,
                "business_name": businessName,
                "business_address": businessAddress,
            }
        }


        if (createMode) {
            sendData["user"]["password"] = tempPassword
            
            const create_user_response = await adminAddUser(sendData)
            if (create_user_response.data != null) {
                alert("User Successfully Created.")
                history.push("/myorders/users")
            }
        }

        if (editMode) {
            const edit_user_response = await adminEditUser(userid, sendData)
            if (edit_user_response.data != null) {
                alert("User Successfully Saved.")
                history.push("/myorders/users")
            }
        }
    }

    const disableUserAccess = async () => {
        const disable_user_access_response = await adminSuspendUserAccess(userid)
        if (disable_user_access_response.data != null) {
            alert("Access has now been DISABLED for this user.")
            history.push("/myorders/users")
        }
    }

    const enableUserAccess = async () => {
        const enable_user_access_response = await adminUnsuspendUserAccess(userid)
        if (enable_user_access_response.data != null) {
            alert("Access has now been ENABLED for this user.")
            history.push("/myorders/users")
        }
    }

    const getRoleName = (role) => {
        return role === "Admin Role" ? "Admin" : role === "Driver Role" ? "Driver" : role === "Outlet Role" ? "Dispensary" : ""
    }

    const handleUserRoleSelectChange = (e) => {
        var options = e.target.options
        var value = []
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value)
          }
        }
        setUserType(value)
    }

    return (
        <tr>
            <td className="admin-users-edit-table-cell">
                <div>
                    <label>First Name:</label>
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                </div>
                <div>
                    <label>Last Name:</label>
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
                </div>
                <div>
                    <label>Email Address:</label>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                <div>
                    <label>Phone Number:</label>
                    <input type="text" inputMode="numeric" defaultValue={phoneNumber} onKeyUp={(e) => phoneOnly(e, setPhoneNumber)}></input>
                </div>
                { createMode && 
                    <div>
                        <label>Temp Password:</label>
                        <input type="text" value={tempPassword} onChange={(e) => setTempPassword(e.target.value)}></input>
                    </div>
                }
                <div>
                    <label>User Type:</label>
                    <select className="user-role-select" value={userType} multiple={true} onChange={handleUserRoleSelectChange}>
                        { userRoles.map(role => (
                            <option value={role._id}>{getRoleName(role.name)}</option>
                        )) }
                    </select>
                </div>
                { editMode && 
                    <div>
                        <label>Business Name:</label>
                        <input type="text" value={businessName} onChange={(e) => setBusinessName(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Business License Number:</label>
                        <input type="text" value={businessLicenseNumber} onChange={(e) => setBusinessLicenseNumber(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Business Address:</label>
                        <input type="text" value={businessAddress} onChange={(e) => setBusinessAddress(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Driver License Number:</label>
                        <input type="text" value={driverLicenseNumber} onChange={(e) => setDriverLicenseNumber(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Driver Badge Number:</label>
                        <input type="text" value={driverBadgeNumber} onChange={(e) => setDriverBadgeNumber(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Driver Car Make:</label>
                        <input type="text" value={driverCarMake} onChange={(e) => setDriverCarMake(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Driver Car Model:</label>
                        <input type="text" value={driverCarModel} onChange={(e) => setDriverCarModel(e.target.value)}></input>
                    </div>
                }
                { editMode && 
                    <div>
                        <label>Driver Car License Plate:</label>
                        <input type="text" value={driverCarPlate} onChange={(e) => setDriverCarPlate(e.target.value)}></input>
                    </div>
                }
                <div>
                    <button type="button" onClick={() => userSubmitted()}>Save</button>

                    { (editMode && !userSuspended) && 
                        <button style={{background: "#bd3e3e"}} onClick={() => disableUserAccess()}>Disable Access</button>
                    }
                    { (editMode && userSuspended) && 
                        <button onClick={() => enableUserAccess()}>Enable Access</button>
                    }

                </div>
            </td>
        </tr>
    )
}

export default AdminUsersEditTableCell
