import { Helmet } from "react-helmet"
import AWSImage from "../components/Shared/AWSImage"

import "../components/Styles/AboutPageStyles/aboutPage.scss"

function AboutPage() {
  return (
    <div className="about-page-container">
        <Helmet>
            <title>Blaze To Go Delivery Service - About Us</title>
            <meta name="description" content="About our Cannabis Delivery Services" />
        </Helmet>

        <div className="about-page-img">
          <AWSImage fileKey={"about/aboutus.png"} />
        </div>
        
    </div>
  )
}

export default AboutPage
