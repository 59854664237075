import DispensariesTable from "../components/DispensariesPage/DispensariesTable"

import "../components/Styles/DispensariesPageStyles/dispensariesPage.scss"

const DispensariesPage = () => {
    return (
        <div className="dispensaries-page-container">
            <DispensariesTable />
        </div>
    )
}

export default DispensariesPage
