import { useState, useEffect } from "react"
import { adminGetUsers } from "../../components/Utils/APIManager"
import AdminUsersTable from "../../components/AdminPages/AdminUsersPage/AdminUsersTable"

import "../../components/Styles/AdminPageStyles/adminUsersPage.scss"

const AdminUsersPage = ({ mode }) => {
    const viewMode = mode === "view"

    const [users, setUsers] = useState([])

    useEffect(() => {
        if (viewMode) {
            getUsers()
        }
    }, [])

    const getUsers = async () => {
        const users_response = await adminGetUsers()
        if (users_response.data != null) {
            setUsers(users_response.data.users)
        }
    }

    return (
        <div className="admin-users-page-container">
            <AdminUsersTable mode={mode} users={users} />
        </div>
    )
}

export default AdminUsersPage
