import { useState, useEffect, useContext } from "react"
import { useParams } from "react-router"
import { UserContext } from "../../components/Utils/UserManager"
import { adminGetOutletInventory, budtenderGetOutletInventory } from "../../components/Utils/APIManager"
 import AdminOutletInventoryTable from "../../components/AdminPages/AdminOutletInventoryPage/AdminOutletInventoryTable"

import "../../components/Styles/AdminPageStyles/adminOutletInventoryPage.scss"

const AdminOutletInventoryPage = ({ mode }) => {
    const { isOutletOwner } = useContext(UserContext)
    const viewMode = mode === "view"
    const { outletid } = useParams()

    const [locations, setLocations] = useState([])
    const [inventory, setInventory] = useState(null)

    useEffect(() => {
        getOutletInventory()
    }, [])

    const getOutletInventory = async () => {
        const outlet_inventory_response = !isOutletOwner ? await adminGetOutletInventory(outletid) : await budtenderGetOutletInventory(outletid)
        if (outlet_inventory_response.data != null) {
            setLocations(outlet_inventory_response.data.inventory.outlet.locations)
            setInventory(outlet_inventory_response.data.inventory)
        }
    }

    return (
        <div className="admin-outlet-inventory-page-container">
            { inventory != null && 
                <AdminOutletInventoryTable mode={mode} locations={locations} inventory={inventory} />
            }
        </div>
    )
}

export default AdminOutletInventoryPage