import { useState } from "react"
import { useHistory } from "react-router"
import { userRequestSMS, userUpdatePassword } from "../../components/Utils/APIManager"
import AdminLogo from "../../components/AdminPages/Shared/AdminLogo"


import "../../components/Styles/AdminPageStyles/adminHelpPage.scss"

const AdminHelpPage = () => {
    let history = useHistory()

    const [helpStep, setHelpStep] = useState(1)
    
    const [email, setEmail] = useState(null)
    const [resetCode, setResetCode] = useState(null)
    const [newPassword, setNewPassword] = useState(null)

    const helpStepOneFormSubmitted = async (e) => {
        e.preventDefault()

        if (email === null || email.trim().length === 0) {
            alert("Email is Required")
            return false
        }

        const user_request_sms_response = await userRequestSMS(email)
        if (user_request_sms_response.data != null) {
            setHelpStep(2)
        }
    }

    const helpStepTwoFormSubmitted = async (e) => {
        e.preventDefault()

        if (resetCode === null || resetCode.trim().length === 0) {
            alert("Reset Code is Required")
            return false
        }

        if (newPassword === null || newPassword.trim().length === 0) {
            alert("New Password is Required")
            return false
        }

        const sendData = {
            "user": {
                "password": newPassword,
                "email": email
            },
            "code": resetCode
        }

        const user_update_password_response = await userUpdatePassword(sendData)
        if (user_update_password_response.data != null) {
            alert("Your password has been successfully reset. You may now Log In with your new password.")
            history.push("/myorders")
        }
    }

    return (
        <div className="admin-help-page-container">
            
            <AdminLogo logoText={"Forgot / Change Password"} displayBackBtn={false} />

            { helpStep === 1 && 
                <form onSubmit={helpStepOneFormSubmitted}>
                    <div className="ahp-fields-container">
                        <label>Enter your Email Address:</label>
                        <input placeholder="email" name="email" onChange={(e) => setEmail(e.target.value)}></input>
                        <button type="submit">Next</button>
                    </div>
                </form>
            }

            { helpStep === 2 &&
                <form onSubmit={helpStepTwoFormSubmitted}>
                    <div className="ahp-fields-container ahp-step-two-fields">
                        <label>A Reset Code has been sent to your phone. Enter your Reset Code, and your new password.</label>
                        <input placeholder="Reset Code" inputMode="numeric" onChange={(e) => setResetCode(e.target.value)}></input>
                        <input placeholder="New Password" type="password" name="password" onChange={(e) => setNewPassword(e.target.value)}></input>
                        <button type="submit">Change Password</button>
                    </div>
                </form>
            }

        </div>
    )
}

export default AdminHelpPage
