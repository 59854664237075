import "../Styles/DispensariesPageStyles/dispensariesTableHeadCell.scss"

const DispensariesTableHeadCell = () => {
    return (
        <tr className="dispensaries-table-head-cell">
            <td>
                <label>Dispensaries</label>
                <span>Viewing Locations In: <p>Denver, CO</p></span>
            </td>
        </tr>
    )
}

export default DispensariesTableHeadCell
