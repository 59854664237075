import { useHistory } from "react-router"
import { stringToPhoneFormat } from "../../Utils/StringFormatters"


const AdminOutletsTableCell = ({ outlet }) => {
    let history = useHistory()

    const adminOutletsTableCellClicked = () => {
        if (outlet._id) {
            history.push(`/myorders/outlets/${outlet._id}`)
        }
    }

    const outletPhoneLabel = () => {
        if (outlet.phone_number) {
            return stringToPhoneFormat(outlet.phone_number, true)
        }else{
            return "!Missing Phone!"
        }
    }

    return (
        <tr onClick={adminOutletsTableCellClicked}>
            <td className="admin-outlets-table-cell">
                <div>
                    <label>{outlet.name}</label>
                    <span>{outletPhoneLabel()}</span>
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletsTableCell
