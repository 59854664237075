import { useState, useRef, useContext } from "react"
import { UserContext } from "../../Utils/UserManager"
import { adminSetDriverForOrder, adminSetBudtenderForOrder, adminSetScheduleForOrder, adminReleaseOrder, adminMarkOrderInTransit, adminMarkOrderDelivered, adminDriverMarkOrderInTransit, adminDriverMarkOrderDelivered, adminOrderDetails, adminGetDriverOrderDetails, adminCancelOrder, adminDriverCancelOrder, adminGetOutletOrderDetails } from "../../Utils/APIManager"
import { timeInputToLocaleFormat, stringToPhoneFormat, orderStatusDisplay, getDriverUserDisplayByID, getBudtenderUserDisplayByID, centsToDollarsDisplay, productGramsDisplay, formatAddressHash, formatAddressHashForURLQuery } from "../../Utils/StringFormatters"
import { awsPutObject } from "../../Utils/awsHandler"
import AWSImage from "../../Shared/AWSImage"
import Moment from "react-moment"
import moment from "moment"
import AdminModal from "../Shared/AdminModal"
import SignatureCanvas from "../../Shared/SignatureCanvas"

import "../../Styles/AdminPageStyles/adminOrdersTableCell.scss"

const AdminOrdersTableCell = ({ order, repUsers, driverUsers, getOrders, setSelectedStatus }) => {
    const { user, getUserRoles } = useContext(UserContext)
    const userRoles = getUserRoles()

    const driverSelectRef = useRef()
    const repSelectRef = useRef()
    const scheduleRef = useRef()
    let sigRef = {}

    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState("Driver")

    const [selectedDriver, setSelectedDriver] = useState(() => {
        return order.driver != null ? order.driver._id : null
    })
    const [selectedRep, setSelectedRep] = useState(() => {
        return order.budtender != null ? order.budtender._id : null
    })
    const [selectedSchedule, setSelectedSchedule] = useState(() => {
        return order.schedule != null ? new Date(order.schedule).toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric"}) : null
    })
    const [orderDetails, setOrderDetails] = useState(null)

    const handleModalOpen = (type) => {
        let validModalRequest = false

        // Admin/Driver/Outlet attempting to see order details
        if (type === "OrderDetails") {
            validModalRequest = true
        }

        // Admin/Driver attempting to capture customer signature
        if (type === "CustomerSignature") {
            if (order.status === "inTransit" && (userRoles.isAdmin === true || userRoles.isDriver === true)) {
                validModalRequest = true
            }
        }

        // Admin attempting to assign a driver or Schedule
        if ((type === "Driver" || type === "Schedule" || type === "Rep") && userRoles.isAdmin === true) {
            if (order.status != "delivered") { // assignment unallowed on delivered status
                validModalRequest = true
            }
        }

        if (validModalRequest) {
            setModalType(type)
            setShowModal(true)
        }
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const handleDriverSelect = async () => {
        const driverID = driverSelectRef.current.value
        const sendData = {
            "driverId": driverID
        }

        const driver_select_response = await adminSetDriverForOrder(order._id, sendData)
        if (driver_select_response.data != null) {
            setSelectedDriver(driverID)
            handleModalClose()
        }
    }

    const handleRepSelect = async () => {
        const repID = repSelectRef.current.value
        const sendData = {
            "budtenderId": repID
        }

        const rep_select_response = await adminSetBudtenderForOrder(order._id, sendData)
        if (rep_select_response.data != null) {
            setSelectedRep(repID)
            handleModalClose()
        }
    }
    
    const handleScheduleSelect = async () => {
        if (scheduleRef.current.value === null || scheduleRef.current.value === undefined || scheduleRef.current.value.trim().length === 0) {
            alert("Please choose a time")
            return
        }

        const today = new Date()
        const schedule = new Date(today.toDateString() + ' ' + scheduleRef.current.value)
        const schedule_iso = schedule.toISOString()

        const sendData = {
            "schedule": schedule_iso
        }

        const schedule_select_response = await adminSetScheduleForOrder(order._id, sendData)
        if (schedule_select_response.data != null) {
            setSelectedSchedule(scheduleRef.current.value)
            handleModalClose()
        }
    }

    const handleOrderDetails = async () => {

        if (userRoles.isAdmin === true) {
            const order_details_response = await adminOrderDetails(order._id)
            if (order_details_response.data != null) {
                setOrderDetails(order_details_response.data.order)
                handleModalOpen("OrderDetails")
            }

            return
        }

        if (userRoles.isDriver === true) {
            const order_details_response = await adminGetDriverOrderDetails(order._id)
            if (order_details_response.data != null) {
                setOrderDetails(order_details_response.data.order)
                handleModalOpen("OrderDetails")
            }

            return
        }

        if (userRoles.isDispensary === true) {
            const order_details_response = await adminGetOutletOrderDetails(order._id)
            if (order_details_response.data != null) {
                setOrderDetails(order_details_response.data.order)
                handleModalOpen("OrderDetails")
            }

            return
        }
    }
    
    const handleCancelOrder = async (cancelReason, cancelOtherReason) => {
        if (cancelReason.length === 0) {
            alert("A Reason Must Be Selected")
            return
        }
        if (cancelReason === "Other" && cancelOtherReason.trim().length === 0) {
            alert("A Reason Must Be Entered")
            return
        }


        const sendData = {
            "reason": cancelReason === "Other" ? `${cancelReason} - ${cancelOtherReason.trim()}` : cancelReason
        }

        if (userRoles.isAdmin === true) {
            const cancel_order_response = await adminCancelOrder(order._id, sendData)
            if (cancel_order_response.data != null) {
                alert("Order Has Been Successfully Canceled")
                getOrders()
            }
        }

        if (userRoles.isDriver === true) {
            const cancel_order_response = await adminDriverCancelOrder(order._id, sendData)
            if (cancel_order_response.data != null) {
                alert("Order Has Been Successfully Canceled")
                getOrders()
            }
        }
    }

    const handleCustomerSignature = async () => {


        let photo_url = ""
        const base64 = sigRef.toDataURL("image/png")
        let signatureBlob = await fetch(base64).then(r => r.blob())

        const dateNow = moment(Date.now()).format('MM-DD-YY')
        const timeNow = moment(Date.now()).format("hh-mm")
        const fileName = `csig/${dateNow}/${timeNow}`
        let file = new File([signatureBlob], fileName, { type: signatureBlob.type, lastModified: Date.now() })
        const uplRes = await awsPutObject(file, fileName)
        if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
            photo_url = fileName
        }


        const sendData = {
            "signature": photo_url
        }

        if (userRoles.isAdmin === true) {
            const delivered_order_response = await adminMarkOrderDelivered(order._id, sendData)
            if (delivered_order_response.data != null) {
                alert("Order has been successfully set Delivered")
                setSelectedStatus("Delivered")
                getOrders()
            }
        }

        if (userRoles.isDriver === true) {
            const delivered_order_response = await adminDriverMarkOrderDelivered(order._id, sendData)
            if (delivered_order_response.data != null) {
                alert("Order has been successfully set Delivered")
                setSelectedStatus("Delivered")
                getOrders()
            }
        }
    }

    const DriverSelect = () => {

        return (
            <div>
                <div className="modal-head">
                    <label>Select a Driver</label>
                </div>
                <div className="aotc-select-container">
                    <select ref={driverSelectRef}>
                        <option value="">Choose a Driver</option>

                        { driverUsers != null && driverUsers.map(user => (
                            <option value={user._id} selected={selectedDriver === user._id}>{user.first_name} {user.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="modal-foot">
                    <button type="button" onClick={handleModalClose}>Close</button>
                    <button className="confirm-btn" type="button" onClick={handleDriverSelect}>Confirm</button>
                </div>
            </div>

        )
    }

    const RepSelect = () => {

        return (
            <div>
                <div className="modal-head">
                    <label>Select a Rep</label>
                </div>
                <div className="aotc-select-container">
                    <select ref={repSelectRef}>
                        <option value="">Choose a Rep</option>

                        { repUsers != null && repUsers.map(user => (
                            <option value={user._id} selected={selectedRep === user._id}>{user.first_name} {user.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="modal-foot">
                    <button type="button" onClick={handleModalClose}>Close</button>
                    <button className="confirm-btn" type="button" onClick={handleRepSelect}>Confirm</button>
                </div>
            </div>

        )
    }

    const ScheduleSelect = () => {

        return (
            <div>
                <div className="modal-head">
                    <label>Select a Time</label>
                </div>
                <div className="aotc-schedule-container">
                    <input defaultValue={selectedSchedule} ref={scheduleRef} placeholder={"Choose a Time"} type="time"></input>
                </div>
                <div className="modal-foot">
                    <button type="button" onClick={handleModalClose}>Close</button>
                    <button className="confirm-btn" type="button" onClick={handleScheduleSelect}>Confirm</button>
                </div>
            </div>
        )
    }

    const OrderDetails = () => {

        const [cancelOrderMode, setCancelOrderMode] = useState(false)
        const [cancelReason, setCancelReason] = useState("")
        const [cancelOtherReason, setCancelOtherReason] = useState("")

        const getOutletLocationAddress = (forURLQuery) => {
            let address = ""
            for (let i = 0; i < orderDetails.outlet.locations.length; i++) {
                const aLocation = orderDetails.outlet.locations[i]
                if (aLocation.locationId === orderDetails.locationId) {
                    address = forURLQuery ? `http://maps.apple.com/?q=${aLocation.street}+${aLocation.city}+${aLocation.state}+${aLocation.zipcode}` : `${aLocation.street}, ${aLocation.city}, ${aLocation.state} ${aLocation.zipcode}`
                }
            }
            return address
        }

        const openImageInNewTab = (e) => {
            const imgSrc = e.target.src

            var image = new Image();
            image.src = imgSrc
    
            var w = window.open("");
            w.document.write(image.outerHTML);
        }

        return (
            <div>
                <div className="modal-head">
                    <label>{cancelOrderMode === false ? "Order Details" : "Cancel Order"}</label>
                </div>
                { cancelOrderMode === true &&
                    <div className="aotc-order-details-cancel-container">
                        <select onChange={(e) => setCancelReason(e.target.value)}>
                            <option value="">Choose a Reason</option>
                            <option value="Invalid license">Invalid license</option>
                            <option value="Customer not present">Customer not present</option>
                            <option value="Not a private residence">Not a private residence</option>
                            <option value="Other">Other</option>
                        </select>

                        { cancelReason === "Other" &&
                            <textarea placeholder="Enter a reason" onChange={(e) => setCancelOtherReason(e.target.value)}></textarea>
                        }
                    </div>
                }

                { (cancelOrderMode === false && orderDetails != null) &&
                    <div className="aotc-order-details-container">
                        <div className="aotc-od-section">
                            <label>{"Departure Date & Time"}</label>
                            <span>{ orderDetails.departed_at != null ? <Moment format="MM/DD/YYYY @ hh:mma">{orderDetails.departed_at}</Moment> : "Currently Not Available"}</span>
                        </div>
                        <div className="aotc-od-section">
                            <label>{"Delivery Date & Time"}</label>
                            <span>{ orderDetails.delivered_at != null ? <Moment format="MM/DD/YYYY @ hh:mma">{orderDetails.delivered_at}</Moment> : "Currently Not Available"}</span> 
                        </div>
                        <div className="aotc-od-section">
                            <label>{"Order Number"}</label>
                            <span>{orderDetails.order_code || ""}</span>
                        </div>
                        <div className="aotc-od-section">
                            <label>{"Order Date"}</label>
                            <span>Order Placed: <Moment format="MM/DD/YYYY @ hh:mma">{orderDetails.createdAt}</Moment></span>
                        </div>
                        <div className="aotc-od-section">
                            <label>Origination</label>
                            <span>Entity: {orderDetails.outlet?.name}</span>
                            <span>License Number: {orderDetails.outlet?.business_number}</span>
                            <span>Address: <a href={getOutletLocationAddress(true)} target="_blank">{getOutletLocationAddress()}</a></span>
                            <span>Phone Number: <a href={`tel:${orderDetails.outlet?.phone_number}`}>{stringToPhoneFormat(orderDetails.outlet?.phone_number)}</a></span>
                        </div>
                        <div className="aotc-od-section">
                            <label>Destination</label>
                            <span>Name: {orderDetails.business_name || `${orderDetails.customer.firstName} ${orderDetails.customer.lastName}`}</span>
                            <span>License Number: {orderDetails.business_number || "license below"}</span>
                            { orderDetails.patient_registration_number != null &&
                                <span>Patient Registration #: {orderDetails.patient_registration_number}</span>
                            }
                            <span>Address: <a href={formatAddressHashForURLQuery(orderDetails.address)} target="_blank">{formatAddressHash(orderDetails.address)}</a></span>
                        </div>
                        {orderDetails.driver != null &&
                            <div className="aotc-od-section">
                                <label>Transporter</label>
                                <span>Business Name: {orderDetails.driver.business_name}</span>
                                <span>License: {orderDetails.driver.business_license_number}</span>
                                <span>Address: {orderDetails.driver.business_address}</span>
                                <span>Phone: {stringToPhoneFormat(orderDetails.driver.phone_number)}</span>
                                <span>Driver Name: {`${orderDetails.driver.first_name} ${orderDetails.driver.last_name}`}</span>
                                <span>Driver License No: {orderDetails.driver.driver_license_number}</span>
                                <span>Badge Number: {orderDetails.driver.driver_badge_number}</span>
                                <span>Make: {orderDetails.driver.car?.make}</span>
                                <span>Model: {orderDetails.driver.car?.model}</span>
                                <span>License Plate: {orderDetails.driver.car?.plate}</span>
                            </div>
                        }
                        { orderDetails.orderItems != null &&
                            <div className="aotc-od-section">
                                <label>Products Details</label>
                                { orderDetails.orderItems.map(item => 
                                item.productGrams != null ?
                                    (<span>{`${item.productName || "!Missing Name!"} (${productGramsDisplay(item.productGrams)}) - Qty: (${item.quantityPurchased})`}</span>) 
                                :
                                    (<span>{`${item.productName || "!Missing Name!"} - Qty: (${item.quantityPurchased})`}</span>) 
                                )}
                            </div>
                        }
                        <div className="aotc-od-section">
                            <label>Order Total</label>
                            <span>Product Cost: {centsToDollarsDisplay(orderDetails.total_in_cents)}</span>
                            <span>Delivery Fee: {centsToDollarsDisplay(orderDetails.customer.externalId ? parseInt(orderDetails.customer.externalId) : 0)}</span>
                            <span>Total: {centsToDollarsDisplay(orderDetails.total_in_cents + parseInt(orderDetails.customer.externalId ? orderDetails.customer.externalId : 0))}</span>
                        </div>
                        { (orderDetails.customer_licc != null && orderDetails.customer_licc.length > 0) &&
                            <div className="aotc-od-section">
                                <label>Customer Drivers License</label>
                                <AWSImage fileKey={orderDetails.customer_licc} imageOnClick={openImageInNewTab} />
                            </div>
                        }
                        { (orderDetails.customer_signature != null && orderDetails.customer_signature.length > 0) &&
                            <div className="aotc-od-section">
                                <label>Customer Signature</label>
                                <AWSImage fileKey={orderDetails.customer_signature} imageOnClick={openImageInNewTab} />
                            </div>
                        }
                    </div>
                }
                <div className="modal-foot">
                    <button type="button" onClick={handleModalClose}>Close</button>
                    { (userRoles.isAdmin === true || userRoles.isDriver === true) &&
                        
                        <button className="cancel-btn" type="button" onClick={() => {
                            if (cancelOrderMode === false) {
                                setCancelOrderMode(true)
                            }else{
                                handleCancelOrder(cancelReason, cancelOtherReason)
                            }
                        }}>Cancel Order</button>
                    }
                </div>
            </div>
        )
    }

    const CustomerSignature = () => {

        

        return (
            <div>
                <div className="modal-head">
                    <label>Signature</label>
                </div>
                <div className="aotc-signature-container">
                    <SignatureCanvas ref={(ref) => { sigRef = ref; }} canvasProps={{clearOnResize: false}} />
                </div>
                <div className="modal-foot">
                    <button type="button" onClick={handleModalClose}>Close</button>
                    <button className="confirm-btn" type="button" onClick={handleCustomerSignature}>Done</button>
                </div>
            </div>
        )
    }

    const handleOrderStatusChange = async (orderStatus) => {

        if (userRoles.isAdmin === true) {

            if (orderStatus === "inQueue") {
                if (selectedDriver === null && selectedSchedule === null) {
                    alert("You must assign a Driver and Schedule before Releasing this order")
                    return
                }
                
                if (window.confirm("Are you sure you want to move this order to Released?")) {
                    const release_order_response = await adminReleaseOrder(order._id)
                    if (release_order_response.data != null) {
                        alert("Order has been successfully released")
                        setSelectedStatus("Released")
                        getOrders()
                    }
                }else{
                    return
                }
            }

            if (orderStatus === "released") {
                if (window.confirm("Are you sure you want to move this order to In Transit (Out)?")) {
                    const intransit_order_response = await adminMarkOrderInTransit(order._id)
                    if (intransit_order_response.data != null) {
                        alert("Order has been successfully set In Transit (Out)")
                        setSelectedStatus("Out")
                        getOrders()
                    }
                }else{
                    return
                }
            }

            if (orderStatus === "inTransit") {
                handleModalOpen("CustomerSignature")
            }

        }

        if (userRoles.isDriver === true) {

            if (orderStatus === "released") {
                if (window.confirm("Are you sure you want to move this order to In Transit (Out)?")) {
                    const intransit_order_response = await adminDriverMarkOrderInTransit(order._id)
                    if (intransit_order_response.data != null) {
                        alert("Order has been successfully set In Transit (Out)")
                        setSelectedStatus("Out")
                        getOrders()
                    }
                }else{
                    return
                }
            }

            if (orderStatus === "inTransit") {
                handleModalOpen("CustomerSignature")
            }
        }
    }


    return (
        <tr className="admin-orders-table-cell" key={order._id} id={`aotc-${order._id}`}>
            <td>
                <div className="aotc-section">
                    <label>Customer: <span>{`${order.customer?.firstName} ${order.customer?.lastName}`}</span></label>
                    <label>Date: <span><Moment format={"MM/DD/YY"}>{order.createdAt}</Moment></span></label>
                </div>
                <div className="aotc-section">
                    <label>Phone #: <span><a href={`tel:${order.customer?.phone}`}>{stringToPhoneFormat(order.customer?.phone)}</a></span></label>
                    <label>Time: <span><Moment format={"hh:mma"}>{order.createdAt}</Moment></span></label>
                </div>
                <div className="aotc-section">
                    <label>Address: <span><a href={formatAddressHashForURLQuery(order.address)} target="_blank">{formatAddressHash(order.address)}</a></span></label>
                </div>
                <div className="aotc-section aotc-assigns" style={{marginTop: "22px"}}>
                    <label onClick={() => handleModalOpen("Driver")}>Driver: <span>{selectedDriver != null && getDriverUserDisplayByID(driverUsers, selectedDriver, order.driver != null ? `${order.driver.first_name} ${order.driver.last_name}` : "")}</span></label>
                    <label onClick={() => handleModalOpen("Schedule")}>Schedule: <span>{ selectedSchedule != null && timeInputToLocaleFormat(selectedSchedule)}</span></label>
                </div>
                <div className="aotc-section aotc-assigns">
                    <label onClick={() => handleModalOpen("Rep")}>Rep: <span>{selectedRep != null && getBudtenderUserDisplayByID(repUsers, selectedRep, order.budtender != null ? `${order.budtender.first_name} ${order.budtender.last_name}` : "")}</span></label>
                </div>
                <div className="aotc-foot">
                    <label>{`${order.outlet?.name}`} <span onClick={() => handleOrderDetails()}>(View Order)</span></label>
                    <label className="aotc-foot-status" onClick={() => handleOrderStatusChange(order.status)}>{orderStatusDisplay(order.status)}</label>
                </div>
                <AdminModal handleClose={handleModalClose} show={showModal} children=
                    {
                        modalType === "Driver" ? <DriverSelect /> :
                        modalType === "Rep" ? <RepSelect /> :
                        modalType === "Schedule" ? <ScheduleSelect /> :
                        modalType === "OrderDetails" ? <OrderDetails /> :
                        modalType === "CustomerSignature" ? <CustomerSignature />
                        : <div></div>
                    } />
            </td>
        </tr>
    )
}

export default AdminOrdersTableCell
