import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router"
import { adminGetOrders, adminGetDriverUsers, adminGetBudtenderUsers, adminGetDriverOrders, adminGetOutletOrders } from "../../components/Utils/APIManager"
import { getDriverUserDisplayByID, timeInputToLocaleFormat, stringToPhoneFormat, centsToDollarsDisplay } from "../../components/Utils/StringFormatters"
import { UserContext } from "../../components/Utils/UserManager"
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import { Loader } from "@googlemaps/js-api-loader"
import moment from 'moment'
import AdminOrdersTableCell from "../../components/AdminPages/AdminOrdersPage/AdminOrdersTableCell"
import AdminModal from "../../components/AdminPages/Shared/AdminModal"


import "../../components/Styles/AdminPageStyles/adminOrdersPage.scss"

const AdminOrdersPage = () => {
    let history = useHistory()
    const { user, getUserRoles } = useContext(UserContext)

    const userRoles = getUserRoles()

    const statusTypes = ["In Queue", "Released", "Out", "Delivered"]
    const [selectedStatus, setSelectedStatus] = useState("In Queue")

    const [orders, setOrders] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([])

    const [driverUsers, setDriverUsers] = useState([])
    const [budtenderUsers, setBudtenderUsers] = useState([])

    useEffect(() => {
        getOrders()
        getDriverUsers()
        getBudtenderUsers()
    }, [])

    const getOrders = async () => {

        if (userRoles.isAdmin === true) {
            const orders_response = await adminGetOrders()
            if (orders_response.data != null) {
                setOrders(orders_response.data.orders)
                setFilteredOrders(orders_response.data.orders)
            }

            return
        }

        if (userRoles.isDriver === true) {
            const orders_response = await adminGetDriverOrders()
            if (orders_response.data != null) {
                setOrders(orders_response.data.orders)
                setFilteredOrders(orders_response.data.orders)
            }

            return
        }

        if (userRoles.isDispensary === true) {
            const orders_response = await adminGetOutletOrders()
            if (orders_response.data != null) {
                setOrders(orders_response.data.orders)
                setFilteredOrders(orders_response.data.orders)
            }

            return
        }
    }

    const getDriverUsers = async () => {
        if (userRoles.isAdmin === true) {
            const drivers_response = await adminGetDriverUsers()
            if (drivers_response.data != null) {
                setDriverUsers(drivers_response.data.users)
            }
        }
    }

    const getBudtenderUsers = async () => {
        if (userRoles.isAdmin === true) {
            const budtenders_response = await adminGetBudtenderUsers()
            if (budtenders_response.data != null) {
                setBudtenderUsers(budtenders_response.data.users)
            }
        }
    }

    const getSelectedStatusClassName = (status) => {
        return status === selectedStatus ? "aop-control-selected" : ""
    }

    const searchChanged = (e) => {
        const searchString = e.target.value

        let filtered = []
        for (let i = 0; i < orders.length; i++) {
            const anOrder = orders[i]

            const searchFor = [
                `${anOrder.customer?.firstName} ${anOrder.customer?.lastName}`,
                moment(anOrder.createdAt).format('MM/DD/YY') || "",
                moment(anOrder.createdAt).format('hh:mma') || "",
                anOrder.outlet?.name || "",
                anOrder.order_code || "",
                stringToPhoneFormat(anOrder.customer?.phone) || "",
                getDriverUserDisplayByID(driverUsers, anOrder.driver?._id, `${user.first_name} ${user.last_name}`),
                timeInputToLocaleFormat(new Date(anOrder.schedule).toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric"})),
                `delivery fee ${centsToDollarsDisplay(anOrder.customer.externalId)}`
            ]
            
            const searchForLowerCased = searchFor.map(item => item.toLowerCase())

            if (searchString.trim().length === 0) {
                filtered.push(anOrder)
            }else if (searchForLowerCased.find(item => item.includes(searchString.trim().toLowerCase())) && compareSelectedStatusToOrder(anOrder.status) === true) {
                filtered.push(anOrder)
            }
            
        }

        setFilteredOrders(filtered)
    }

    const compareSelectedStatusToOrder = (status) => {
        let valid = false
        switch (status) {
            case "inQueue":
                valid = "In Queue" === selectedStatus
                break
            case "released":
                valid = "Released" === selectedStatus
                break
            case "inTransit":
                valid = "Out" === selectedStatus
                break
            case "delivered":
                valid = "Delivered" === selectedStatus
                break
            default:
                valid = false
        }

        return valid
    }


    
    /* Driver Map Modal Handling */
    const [showModal, setShowModal] = useState(false)

    const handleModalOpen = () => {
        setShowModal(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const DriverMap = () => {

        const googleLoader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY
        })

        const [customerCoords, setCustomerCoords] = useState(null)
        const [dispensaryCoords, setDispensaryCoords] = useState(null)

        const defaultMapOptions = {
            streetViewControl: false,
            mapTypeControl: false
        }


        const mapContainerStyle = {
            width: "100%",
            height: "400px"
        }
        
        const mapCenter = { // Denver, CO
            lat: 39.7392,
            lng: -104.9903
        }

        const mapOnLoad = () => {
            googleLoader
            .load()
            .then((google) => {

                    const geocoder = new google.maps.Geocoder()

                    let customerLocations = []
                    let dispensaryLocations = []

                    for (let k = 0; k < orders.length; k++) {
                            const anOrder = orders[k]

                            if (compareSelectedStatusToOrder(anOrder.status) === true) {

                                const orderAddress = anOrder.address
                                if (orderAddress != null) {
                                    if (orderAddress && orderAddress.street1 && orderAddress.city && orderAddress.state && orderAddress.zip) {
                                        const customerAddress = `${orderAddress.street1}, ${orderAddress.city}, ${orderAddress.state} ${orderAddress.zip}`
                                        if (customerLocations.indexOf(customerAddress) === -1) {
                                            customerLocations.push({order: anOrder, address: customerAddress})
                                        }
                                    }
                                }

                                const orderOutlet = anOrder.outlet.locations
                                if (orderOutlet != null) {
                                    const orderOutletAdrress = orderOutlet[0]
                                    if (orderOutletAdrress && orderOutletAdrress.street && orderOutletAdrress.city && orderOutletAdrress.state && orderOutletAdrress.zipcode) {
                                        const outletAddress = `${orderOutletAdrress.street}, ${orderOutletAdrress.city}, ${orderOutletAdrress.state} ${orderOutletAdrress.zipcode}`
                                        if (dispensaryLocations.indexOf(outletAddress) === -1) {
                                            dispensaryLocations.push({order: anOrder, address: outletAddress})
                                        }
                                    }
                                }
                        }
                    }

                    

                    // Process Customer Locations
                    let customerLocationCoords = []
                    for (let i = 0; i < customerLocations.length; i++) {
                        setTimeout(() => {
                            const aCustomerLocation = customerLocations[i]
                            const aCustomerAddress = aCustomerLocation.address
                        
                            geocoder.geocode({"address": aCustomerAddress}, (results, status) => {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    const lat = results[0].geometry.location.lat()
                                    const lng = results[0].geometry.location.lng()
    
                                    customerLocationCoords.push({coords: {
                                        position: {lat, lng},
                                        order_details: aCustomerLocation
                                    }})
    
                                    // all addresses have been processed
                                    if (customerLocationCoords.length === customerLocations.length) {
                                        setCustomerCoords(customerLocationCoords)
                                    }
                                }
                            })
                        }, 100)
                    }

                    // Process Dispensary Locations
                    let dispensaryLocationCoords = []
                    for (let j = 0; j < dispensaryLocations.length; j++) {
                        setTimeout(() => {
                            const aDispensaryLocation = dispensaryLocations[j]
                            const aDispensaryAddress = aDispensaryLocation.address
                            
                            geocoder.geocode({"address": aDispensaryAddress}, (results, status) => {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    const lat = results[0].geometry.location.lat()
                                    const lng = results[0].geometry.location.lng()

                                    dispensaryLocationCoords.push({coords: {
                                        position: {lat, lng},
                                        order_details: aDispensaryLocation
                                    }})

                                    // all addresses have been processed
                                    if (dispensaryLocationCoords.length === dispensaryLocations.length) {
                                        setDispensaryCoords(dispensaryLocationCoords)
                                    }
                                }
                            })
                        }, 100)
                    }

                    
            }).catch((e) => {
                throw(e)
            })
        }

        const MarkerWithInfoWindow = ({props}) => {
            const { position, isDispensary, order } = props

            const [infoWindowOpen, setInfoWindowOpen] = useState(false)
            const greenDotImage = "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
        

            const handleInfoWindowOpen = () => {
                setInfoWindowOpen(true)
            }

            const handleInfoWindowClose = () => {
                setInfoWindowOpen(false)
            }

            const handleGetDirections = () => {

                const outletAddress = `${order.order?.outlet?.locations[0]?.street}, ${order.order?.outlet?.locations[0]?.city}, ${order.order?.outlet?.locations[0]?.state} ${order.order?.outlet?.locations[0]?.zipcode}`
                const url = window.open("", "_newtab")
                url.location = isDispensary === false ? `http://maps.google.com/maps?saddr=${outletAddress}&daddr=${order.address}` : `http://maps.google.com/maps?saddr=My+Location&daddr=${order.address}`
            }

            return (
                <Marker position={position} icon={isDispensary === true ? greenDotImage : null} onClick={handleInfoWindowOpen}>
                    { infoWindowOpen === true && 
                        <InfoWindow onCloseClick={handleInfoWindowClose}>
                            <div>
                                { (isDispensary === false && order != null) &&
                                    <div>
                                        <span><b>{`${order.order.customer.firstName} ${order.order.customer.lastName}`}</b></span>
                                        <br></br>
                                        <span>{order.address}</span>
                                        <br></br>
                                        <br></br>
                                        <button onClick={() => handleGetDirections()} style={{float: "right"}}>Get Directions</button>
                                    </div>
                                }
                                { (isDispensary === true && order != null) &&
                                    <div>
                                        <span><b>{order.order.outlet.name}</b></span>
                                        <br></br>
                                        <span>{order.address}</span>
                                        <br></br>
                                        <br></br>
                                        <button onClick={() => handleGetDirections()} style={{float: "right"}}>Get Directions</button>
                                    </div>
                                }
                            </div>
                        </InfoWindow>
                    }
                </Marker>
            )
        }

        return (
            <div>
                <div className="modal-head">
                    <label>Map</label>
                </div>
                <div className="aotc-map-container">
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                        <GoogleMap onLoad={mapOnLoad} options={defaultMapOptions} mapContainerStyle={mapContainerStyle} center={mapCenter} zoom={11}>

                            { (customerCoords != null && customerCoords.length > 0) && customerCoords.map((customer) => ((
                                <MarkerWithInfoWindow props={{position: customer.coords.position, isDispensary: false, order: customer.coords.order_details}} />
                            )))}

                            { (dispensaryCoords != null && dispensaryCoords.length > 0) && dispensaryCoords.map((dispensary) => ((
                                <MarkerWithInfoWindow props={{position: dispensary.coords.position, isDispensary: true, order: dispensary.coords.order_details}} />
                            )))}

                        </GoogleMap>
                    </LoadScript>
                </div>
                <div className="modal-foot">
                    <button type="button" onClick={handleModalClose}>Close</button>
                </div>
            </div>
        )
    }

    return (
        <div className="admin-orders-page-container">
            
            <table>
                <thead>
                    <tr>
                        <td>
                            <div className="aop-head">
                                <label>All Orders</label>
                                <button onClick={() => history.push("/myorders")}>{"<"}</button>
                            </div>
                            <div className="aop-controls">
                                { statusTypes.map(status => (
                                    <button className={getSelectedStatusClassName(status)} onClick={() => setSelectedStatus(status)}>{ status }</button>
                                ))}
                            </div>
                            <div className="aop-search">
                                    <input type="text" placeholder="Search" onChange={searchChanged}></input>
                            </div>
                            <div className="aop-map">
                                    <button type="button" onClick={handleModalOpen}>Show Map</button>
                            </div>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    { filteredOrders.map(order => (
                        compareSelectedStatusToOrder(order.status) === true ? <AdminOrdersTableCell order={order} repUsers={budtenderUsers} driverUsers={driverUsers} getOrders={getOrders} setSelectedStatus={setSelectedStatus} /> : null 
                    ))}
                </tbody>

                
            </table>

            <AdminModal handleClose={handleModalClose} show={showModal} children={<DriverMap />} />
        </div>
    )
}

export default AdminOrdersPage
