import { useState, useEffect, useContext, useRef } from "react"
import { useParams, useHistory } from "react-router"
import { UserContext } from "../../Utils/UserManager"
import AWSImage from "../../Shared/AWSImage"
import { awsPutObject } from "../../Utils/awsHandler"
import { adminGetOutletInventoryProduct, adminAddOutletInventoryProduct, adminEditOutletInventoryProduct, adminDeleteOutletInventoryProduct, budtenderGetOutletInventoryProduct, budtenderAddOutletInventoryProduct, budtenderEditOutletInventoryProduct, budtenderDeleteOutletInventoryProduct } from "../../Utils/APIManager"
import { dollarsToCents, centsToDollars, productGramsDisplay } from "../../Utils/StringFormatters"
import moment from 'moment'

const AdminOutletInventoryEditTableCell = ({ mode }) => {
    let history = useHistory()
    const { isOutletOwner } = useContext(UserContext)
    const createMode = mode === "create"
    const editMode = mode === "edit"

    const { outletid, productid } = useParams()

    const weightNameInput = useRef()
    const weightGramInput = useRef()
    const weightPriceInput = useRef()

    const [locationId, setLocationId] = useState(0)
    const [category, setCategory] = useState("")
    const [productName, setProductName] = useState("")
    const [productType, setProductType] = useState("none")
    const [productionDate, setProductionDate] = useState("")
    const [THC, setTHC] = useState(null)
    const [terpenes, setTerpenes] = useState(null)
    const [CBD, setCBD] = useState(null)
    const [quantity, setQuantity] = useState(null)
    const [weightScale, setWeightScale] = useState("each")
    const [price, setPrice] = useState(null)
    const [weightTiers, setWeightTiers] = useState([])
    const [display, setDisplay] = useState("none")

    const [existingPhoto1Value, setExistingPhoto1Value] = useState(null)
    const [existingPhoto2Value, setExistingPhoto2Value] = useState(null)
    const [existingPhoto3Value, setExistingPhoto3Value] = useState(null)

    const [existingPhoto1Deleted, setExistingPhoto1Deleted] = useState(false)
    const [existingPhoto2Deleted, setExistingPhoto2Deleted] = useState(false)
    const [existingPhoto3Deleted, setExistingPhoto3Deleted] = useState(false)

    const productPhoto1Ref = useRef()
    const productPhoto2Ref = useRef()
    const productPhoto3Ref = useRef()

    const [productPhoto1Attached, setProductPhoto1Attached] = useState(false)
    const [productPhoto2Attached, setProductPhoto2Attached] = useState(false)
    const [productPhoto3Attached, setProductPhoto3Attached] = useState(false)

    const [productPhoto1, setProductPhoto1] = useState(null)
    const [productPhoto2, setProductPhoto2] = useState(null)
    const [productPhoto3, setProductPhoto3] = useState(null)


    useEffect(() => {

        if (editMode) {
            getInventoryProduct()
        }
    }, [])

    const getInventoryProduct = async () => {
        const admin_get_product_response = !isOutletOwner ? await adminGetOutletInventoryProduct(outletid, productid) : await budtenderGetOutletInventoryProduct(outletid, productid)
        if (admin_get_product_response.data != null) {
            const product = admin_get_product_response.data.product

            if (product.locationId != null) {
                setLocationId(product.locationId)
            }
            
            setCategory(product.category)
            setProductName(product.productName)
            setProductType(product.speciesName)
            setProductionDate(moment(product.production_date).format('MM/DD/YY'))
            setPrice(centsToDollars(product.priceInMinorUnits))
            setDisplay(product.display === true ? "true" : "false")
            setQuantity(product.quantity)
            setWeightScale(product.productUnitOfMeasure)
            setWeightTiers(product.weightTierInformation)

            if (product.cannabinoidInformation.length != 0) {
                for (let i = 0; i < product.cannabinoidInformation.length; i++) {
                    const cInfo = product.cannabinoidInformation[i]
                    if (cInfo.name === "thc") {
                        setTHC(cInfo.lowerRange)
                    }else if (cInfo.name === "terpenes") {
                        setTerpenes(cInfo.lowerRange)
                    }else if (cInfo.name === "cbd") {
                        setCBD(cInfo.lowerRange)
                    }
                }
            }

            if (product.images != null && product.images.length > 0) {
                if (product.images[0] !== undefined) {
                    setProductPhoto1Attached(true)
                    setExistingPhoto1Value(product.images[0].path)
                    setProductPhoto1(product.images[0].path)
                }
                if (product.images[1] !== undefined) {
                    setProductPhoto2Attached(true)
                    setExistingPhoto2Value(product.images[1].path)
                    setProductPhoto2(product.images[1].path)
                }
                if (product.images[2] !== undefined) {
                    setProductPhoto3Attached(true)
                    setExistingPhoto3Value(product.images[2].path)
                    setProductPhoto3(product.images[2].path)
                }
            }
        }
    }

    const handleWeightScaleSelectChange = (e) => {
        const value = e.target.value
        setWeightScale(value)
    }

    const handleProductTypeSelectChange = (e) => {
        const value = e.target.value
        setProductType(value === "none" ? null : value) 
    }

    const handleDisplaySelectChange = (e) => {
        const value = e.target.value
        setDisplay(value != "none" ? value : null)
    }

    const weightInfoClicked = () => {
        let alertMessage = ""
        alertMessage += "Name doesnt affect the user display, the gram amount does. Use the key below for the wanted output.\n\n"
        alertMessage += "Gram Amount Display Key:\n"
        alertMessage += "1 = 1g\n"
        alertMessage += "3.5 = 1/8 oz\n"
        alertMessage += "7 = 1/4 oz\n"
        alertMessage += "14 = 1/2 oz\n"
        alertMessage += "28 = 1oz\n"
        alertMessage += "448 = 1lb\n"
        alert(alertMessage)
    }

    const addWeightClicked = () => {
        const nameValue = weightNameInput.current.value
        const gramValue = parseFloat(weightGramInput.current.value)
        const priceValue = parseFloat(weightPriceInput.current.value)

        if (nameValue.trim().length === 0) {
            alert("Name is required when adding a Weight Tier")
            return
        }

        if (isNaN(gramValue) || gramValue < 0.1) {
            alert("Gram Amount is required when adding a Weight Tier")
            return
        }

        if (isNaN(priceValue) || priceValue < 0.01) {
            alert("Price is required when adding a Weight Tier")
            return
        }

        const newWeightTier = {
            "name": nameValue,
            "gramAmount": gramValue,
            "pricePerUnitInMinorUnits": dollarsToCents(priceValue)
        }

        setWeightTiers(oldWeightTiers => [...oldWeightTiers, newWeightTier])

        weightNameInput.current.value = ""
        weightGramInput.current.value = ""
        weightPriceInput.current.value = ""
    }

    const removeWeightClicked = (weightTierIndex) => {
        let updatedWeightTiers = [...weightTiers]
        updatedWeightTiers.splice(weightTierIndex, 1)
        setWeightTiers(updatedWeightTiers)
    }

    const addPhotoClicked = (photoNumber) => {
        if (photoNumber === "1") {
            productPhoto1Ref.current.click()
        }else if (photoNumber === "2") {
            productPhoto2Ref.current.click()
        }else if (photoNumber === "3") {
            productPhoto3Ref.current.click()
        }
    }

    const removePhotoClicked = (photoNumber) => {
        if (photoNumber === "1") {
            setProductPhoto1Attached(false)
            setProductPhoto1(null)
            setExistingPhoto1Deleted(true)

        }else if (photoNumber === "2") {
            setProductPhoto2Attached(false)
            setProductPhoto2(null)
            setExistingPhoto2Deleted(true)

        }else if (photoNumber === "3") {
            setProductPhoto3Attached(false)
            setProductPhoto3(null)
            setExistingPhoto3Deleted(true)
        }
    }

    const handleProductPhoto1 = (e) => {
        if (e.target.files && e.target.files[0]) {
            setProductPhoto1Attached(true)
            setProductPhoto1(e.target.files[0])
        }
    }

    const handleProductPhoto2 = (e) => {
        if (e.target.files && e.target.files[0]) {
            setProductPhoto2Attached(true)
            setProductPhoto2(e.target.files[0])
        }
    }

    const handleProductPhoto3 = (e) => {
        if (e.target.files && e.target.files[0]) {
            setProductPhoto3Attached(true)
            setProductPhoto3(e.target.files[0])
        }
    }

    const productDeleteClicked = async () => {
        const sendData = {
            "product_id": productid
        }

        const delete_product_response = !isOutletOwner ? await adminDeleteOutletInventoryProduct(outletid, sendData) : await budtenderDeleteOutletInventoryProduct(outletid, sendData)
        if (delete_product_response.data != null) {
            history.push(`/myorders/outlets/${outletid}/inventory`)
        }
    }

    const productSubmitted = async () => {
        const gramsEnabled = weightScale === "g"

        if (category === null || category.trim().length === 0) {
            alert("Category is required")
            return false
        }

        if (productName === null || productName.trim().length === 0) {
            alert("Product Name is required")
            return false
        }

        if (!gramsEnabled && (price === null || price === undefined)) {
            alert("Price is required")
            return false
        }

        if (display === null || display === "none") {
            alert("Display is required")
            return false
        }

        let productImages = []
        if (productPhoto1Attached) {
            if (productPhoto1Ref.current != null && productPhoto1Ref.current.files.length > 0) {
                const nameOfProductFile = productName.replace(/\s/g, "")
                const fileName = `outlets/${outletid}/products/one-${nameOfProductFile}`
                let file = new File([productPhoto1], fileName, { type: "image/png", lastModified: Date.now() })
                const uplRes = await awsPutObject(file, fileName)
                if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
                    productImages.push({
                        path: fileName
                    })
                }
            }else if (existingPhoto1Value != null && !existingPhoto1Deleted) {
                productImages.push({
                    path: existingPhoto1Value
                })
            }
        }

        if (productPhoto2Attached) {
            if (productPhoto2Ref.current != null && productPhoto2Ref.current.files.length > 0) {
                const nameOfProductFile = productName.replace(/\s/g, "")
                const fileName = `outlets/${outletid}/products/two-${nameOfProductFile}`
                let file = new File([productPhoto2], fileName, { type: "image/png", lastModified: Date.now() })
                const uplRes = await awsPutObject(file, fileName)
                if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
                    productImages.push({
                        path: fileName
                    })
                }
            }else if (existingPhoto2Value != null && !existingPhoto2Deleted) {
                productImages.push({
                    path: existingPhoto2Value
                })
            }
        }

        if (productPhoto3Attached) {
            if (productPhoto3Ref.current != null && productPhoto3Ref.current.files.length > 0) {
                const nameOfProductFile = productName.replace(/\s/g, "")
                const fileName = `outlets/${outletid}/products/three-${nameOfProductFile}`
                let file = new File([productPhoto3], fileName, { type: "image/png", lastModified: Date.now() })
                const uplRes = await awsPutObject(file, fileName)
                if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
                    productImages.push({
                        path: fileName
                    })
                }
            }else if (existingPhoto3Value != null && !existingPhoto3Deleted) {
                productImages.push({
                    path: existingPhoto3Value
                })
            }
        }
        

        let sendData = {
            "product": {
                "productName": productName,
                "category": category,
                "speciesName": productType != "none" ? productType : "",
                "priceInMinorUnits": !gramsEnabled ? dollarsToCents(price) : 0,
                "display": display === "true" ? true : false,
                "production_date": productionDate,
                "locationId": locationId,
                "quantity": quantity,
                "productUnitOfMeasure": weightScale,
                "images": productImages,
                "weightTierInformation": gramsEnabled ? weightTiers : [],
            }
        }

        let cannabinoidInformation = []
        if (THC != null) {
            cannabinoidInformation.push({
                "name": "thc",
                "lowerRange": THC,
                "upperRange": null
            })
        }
        if (terpenes != null) {
            cannabinoidInformation.push({
                "name": "terpenes",
                "lowerRange": terpenes,
                "upperRange": null
            })
        }
        if (CBD != null) {
            cannabinoidInformation.push({
                "name": "cbd",
                "lowerRange": CBD,
                "upperRange": null
            })   
        }
        sendData["product"]["cannabinoidInformation"] = cannabinoidInformation

        if (createMode) {
            const add_product_response = !isOutletOwner ? await adminAddOutletInventoryProduct(outletid, sendData) : await budtenderAddOutletInventoryProduct(outletid, sendData)
            if (add_product_response.data != null) {
                history.push(`/myorders/outlets/${outletid}/inventory`)
            }
        }

        if (editMode) {
            sendData["product_id"] = productid

            const edit_product_response = !isOutletOwner ? await adminEditOutletInventoryProduct(outletid, sendData) : await budtenderEditOutletInventoryProduct(outletid, sendData)
            if (edit_product_response.data != null) {
                history.push(`/myorders/outlets/${outletid}/inventory`)
            }
        }
    }

    return (
        <tr key={productid != null ? productid : ""}>
            <td className="admin-outlets-inventory-edit-table-cell">
                <div>
                    <label>Category:</label>
                    <input type="text" value={category} onChange={(e) => setCategory(e.target.value)}></input>
                </div>
                <div>
                    <label>Product Name:</label>
                    <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)}></input>
                </div>
                <div>
                    <label>Product Type:</label>
                    <select value={productType} onChange={handleProductTypeSelectChange}>
                        <option value={"none"}>Choose option</option>
                        <option value="Sativa">Sativa</option>
                        <option value="Indica">Indica</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value={"Sativa Hybrid"}>Sativa Hybrid</option>
                        <option value={"Indica Hybrid"}>Indica Hybrid</option>
                    </select>
                </div>
                <div>
                    <label>Production Date:</label>
                    <input type="text" value={productionDate} placeholder="MM/DD/YYYY" onChange={(e) => setProductionDate(e.target.value)}></input>
                </div>
                <div className="aoi-space-between-section-3">
                    <div>
                        <label>THC:</label>
                        <input type="text" value={THC} type="number" placeholder="%" onChange={(e) => setTHC(e.target.value)}></input>
                    </div>
                    <div>
                        <label>Terpenes:</label>
                        <input type="text" value={terpenes} type="number" placeholder="%" onChange={(e) => setTerpenes(e.target.value)}></input>
                    </div>
                    <div>
                        <label>CBD:</label>
                        <input type="text" value={CBD} type="number" placeholder="%" onChange={(e) => setCBD(e.target.value)}></input>
                    </div>
                </div>
                <div>
                    <label>Weight Scale:</label>
                    <select value={weightScale} onChange={handleWeightScaleSelectChange}>
                        <option value={"each"}>Each</option>
                        <option value="g">Grams</option>
                    </select>
                </div>
                <div className={ weightScale === "each" && "aoi-space-between-section-2"}>
                    <div>
                        <label>
                            { weightScale === "g" ? "Total Quantity in Grams (Max Stock):" : "Quantity:" }
                        </label>
                        <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)}></input>
                    </div>
                    { weightScale === "each" &&                  
                        <div>
                            <label>Price Per Unit:</label>
                            <input type="number" value={price} min="0.01" step="0.01" placeholder="$0.00" onChange={(e) => setPrice(e.target.value)}></input>
                        </div>
                    }
                </div>
                { weightScale === "g" &&
                    <div className="aoi-weight-container">
                        <label>Weight Tiers: <p className="info" onClick={() => weightInfoClicked()}></p></label>
                        { weightTiers.length === 0 &&
                            <span>No Available Weight Tiers</span>
                        }
                        { weightTiers.map((tier, index) => (
                            <span>
                                <button onClick={() => removeWeightClicked(index)}>X</button>
                                {index + 1}). { `${tier.name} - ${tier.gramAmount}g (displays: ${productGramsDisplay(tier.gramAmount)}) - $${centsToDollars(tier.pricePerUnitInMinorUnits)}` }
                            </span>
                        )) }
                    </div>
                }
                { weightScale === "g" &&
                    <div className="aoi-add-weight-container">
                        <label>Add Weight Tier:</label>
                        <div className="aoi-space-between-section-3">
                            <div>
                                <input ref={weightNameInput} type="text" placeholder="Name"></input>
                            </div>
                            <div>
                                <input ref={weightGramInput} type="number" min="0.1" step="0.1" placeholder="Gram Amount"></input>
                            </div>
                            <div>
                                <input ref={weightPriceInput} type="number" min="0.01" step="0.01" placeholder="Price"></input>
                            </div>
                        </div>
                        <button className="aoi-add-weight" onClick={() => addWeightClicked()}>Add</button>
                    </div>
                }
                <div>
                    <label>Product Images:</label>
                    <div className="aoi-images-container">
                        <div className="aoi-image">
                            { !productPhoto1Attached &&
                                <span onClick={() => addPhotoClicked("1")}>+</span>
                            }
                            { (productPhoto1Attached && productPhoto1 != null)  &&
                                <div className="aoi-image-remove" onClick={() => removePhotoClicked("1")}>X</div>
                            }
                            { (productPhoto1Attached && productPhoto1 != null && typeof productPhoto1 === "string")  &&
                                <AWSImage fileKey={productPhoto1}></AWSImage>
                            }
                            { (productPhoto1Attached && productPhoto1 != null && typeof productPhoto1 != "string")  &&
                                <img src={URL.createObjectURL(productPhoto1)}></img>
                            }
                            
                            <input ref={productPhoto1Ref} type="file" accept="image/*" onChange={handleProductPhoto1}></input>
                        </div>
                        <div className="aoi-image">
                            { !productPhoto2Attached &&
                                <span onClick={() => addPhotoClicked("2")}>+</span>
                            }
                            { (productPhoto2Attached && productPhoto2 != null)  &&
                                <div className="aoi-image-remove" onClick={() => removePhotoClicked("2")}>X</div>
                            }
                            { (productPhoto2Attached && productPhoto2 != null && typeof productPhoto2 === "string")  &&
                                <AWSImage fileKey={productPhoto2}></AWSImage>
                            }
                            { (productPhoto2Attached && productPhoto2 != null && typeof productPhoto2 != "string")  &&
                                <img src={URL.createObjectURL(productPhoto2)}></img>
                            }

                            <input ref={productPhoto2Ref} type="file" accept="image/*" onChange={handleProductPhoto2}></input>
                        </div>
                        <div className="aoi-image">
                            { !productPhoto3Attached &&
                                <span onClick={() => addPhotoClicked("3")}>+</span>
                            }
                            { (productPhoto3Attached && productPhoto3 != null)  &&
                                <div className="aoi-image-remove" onClick={() => removePhotoClicked("3")}>X</div>
                            }
                            { (productPhoto3Attached && productPhoto3 != null && typeof productPhoto3 === "string")  &&
                                <AWSImage fileKey={productPhoto3}></AWSImage>
                            }
                            { (productPhoto3Attached && productPhoto3 != null && typeof productPhoto3 != "string")  &&
                                <img src={URL.createObjectURL(productPhoto3)}></img>
                            }

                            <input ref={productPhoto3Ref} type="file" accept="image/*" onChange={handleProductPhoto3}></input>
                        </div>
                    </div>
                </div>
                <div>
                    <label>Display:</label>
                    <select value={display} onChange={handleDisplaySelectChange}>
                        <option value={"none"}>Choose option</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <button onClick={() => productSubmitted()}>Save</button>
                    <button style={{background: "#bd3e3e", marginTop: "20px"}} onClick={() => productDeleteClicked()}>Delete</button>
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletInventoryEditTableCell
