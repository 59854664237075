import AWS from 'aws-sdk'

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
const REGION = process.env.REACT_APP_AWS_BUCKET_REGION


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})


const bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

export const awsPutObject = (file, file_name) => {
    document.body.classList.add("loading-indicator") // Add Loading Indicator For Image Uploads
    
    const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: file_name
    }

    return bucket.putObject(params).promise()
}

export const awsGetObject = (file_name) => {

    const params = {
        Bucket: S3_BUCKET,
        Key: file_name
    }

    return bucket.getObject(params).promise()
}
