import { useContext } from "react"
import { Link } from "react-router-dom"
import { RoutesContext } from "../Utils/RoutesManager"
import { dispensaryLocationFormat } from "../Utils/StringFormatters"
import AWSImage from "../Shared/AWSImage"
import Logo from "../Images/logo.png"

import "../Styles/DispensariesDetailPageStyles/dispensariesDetailTableHeadCell.scss"

const DispensariesDetailTableHeadCell = ({ dispensary }) => {
    const { baseOrganization, getGoToRouteForOrganization } = useContext(RoutesContext)

    return (
        <tr className="dispensaries-detail-table-head-cell">
            <td>
                <div>
                    { baseOrganization === "" &&
                        <Link to={getGoToRouteForOrganization(``)}>{"< Dispensaries"}</Link>
                    }
                    <label className="ddt-head-label">Products</label>
                </div>
                <div>
                    { dispensary != null && 
                        <AWSImage fileKey={dispensary.logo} />
                    }
                    { dispensary === null &&
                        <img src={Logo}></img>
                    }
                    { dispensary != null && dispensary.locations[0] != null &&
                        <div className="ddt-location">
                            <label>{dispensary.name}</label>
                            <span>{ dispensaryLocationFormat(dispensary.locations[0]) }</span>
                        </div>
                    }
                </div>
            </td>
        </tr>
    )
}

export default DispensariesDetailTableHeadCell
