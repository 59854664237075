import { Loader } from "@googlemaps/js-api-loader"

const googleLoader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})

const metersToMilesRounded = (meters) => {
    return Math.round(meters * 0.000621371192)
}

export default function getDistanceBetweenLocations(location1, location2, callback) {
    
    googleLoader
    .load()
    .then((google) => {
        const service = new google.maps.DistanceMatrixService()

        const origins = [location1]
        const destinations = [location2]
    
        service.getDistanceMatrix(
            {
                origins: origins,
                destinations: destinations,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC
            }, (response, status) => {
                if (status !== "OK" ) {
                    callback(status)
                }else{
                    for (var i = 0; i < origins.length; i++) {
                        var results = response.rows[i].elements
                        for (var j = 0; j < results.length; j++) {
                            var element = results[j]
                            var distanceValue = element.distance.value
                            
                            callback(metersToMilesRounded(distanceValue))
                        }
                    }
                }
                
            })
    })
    .catch((e) => {
        callback(e)
    })
}