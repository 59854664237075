import Logo from "../components/Images/logo.png"
import Area420Logo from "../components/Images/area420-logo.png"

import "../components/Styles/LandingPageStyles/landingPage.scss"

const LandingPage = ({props}) => {
    const { setLandingComponentAccepted, baseOrganization } = props

    const area420Org = baseOrganization === "area420"

    return (
        <div className="landing-page-container">

            { area420Org ? 
                <div className="a420-logo">
                    <img src={Area420Logo}></img>
                    <label>WHOLESALE</label>
                </div>
            :
                <div>
                    <img src={Logo}></img>
                </div>
            }
            
            <hr></hr>

            { area420Org ?
                <div className="landing-page-text a420-text">
                    <label>
                        I am 21 years or older:
                    </label>
                    <div>
                        <button onClick={() => setLandingComponentAccepted(true)}>Confirm</button>
                    </div>
                </div>
            :
                <div className="landing-page-text">
                    <label>
                        Denver's Premier
                        <br></br>
                        cannabis delivery service
                        <br></br>
                        <br></br>
                        <br></br>
                        I am 21 years or older:
                    </label>
                    <div>
                        <button onClick={() => setLandingComponentAccepted(true)}>Confirm</button>
                    </div>
                </div>
            }


            <hr></hr>
            {/* <div>
                <p>support@cloud9delivers.com</p>
            </div> */}
        </div>
    )
}

export default LandingPage
