import { useState, useContext } from "react"
import { useHistory } from "react-router"
import { RoutesContext } from "../components/Utils/RoutesManager"
import { CartContext } from "../components/Utils/CartManager"
import CartCheckoutTable from "../components/CartPage/CartCheckoutTable"
import CartOrderDetailsTable from "../components/CartPage/CartOrderDetailsTable"

import "../components/Styles/CartPageStyles/cartPage.scss"

const CartPage = () => {
    let history = useHistory()
    const { getGoToRouteForOrganization } = useContext(RoutesContext)
    const { cartItems } = useContext(CartContext)

    const [deliveryAddress, setDeliveryAddress] = useState(null)
    const [deliveryAddress2, setDeliveryAddress2] = useState(null)
    const [deliveryAddressComponents, setDeliveryAddressComponents] = useState(null)

    const cartFormProps = {
        setDeliveryAddress,
        setDeliveryAddress2,
        setDeliveryAddressComponents
    }

    const cartFormSubmitted = (e) => {
        e.preventDefault()

        if (cartItems.length === 0) {
            alert("You have no Items in your cart")
            return false
        }

        if (deliveryAddress === null || deliveryAddress.trim().length === 0 || deliveryAddressComponents === null) {
            alert("Invalid delivery address provided")
            return false
        }

        history.push({
            pathname: getGoToRouteForOrganization("/checkout"),
            state: { delivery_address: deliveryAddress, delivery_address2: deliveryAddress2, delivery_address_components: deliveryAddressComponents }
        })

        return true
    }

    return (
        <div className="cart-page-container">
            <form onSubmit={cartFormSubmitted}>
                <CartCheckoutTable props={cartFormProps} />
            </form>

            <CartOrderDetailsTable />
        </div>
    )
}

export default CartPage
