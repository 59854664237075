import { useState, useEffect } from "react"
import { useParams } from "react-router"
import { getDispensaryCategoryProducts } from "../Utils/APIManager"
import DispensariesProductsTableHeadCell from "./DispensariesProductsTableHeadCell"
import DispensariesProductsTableCell from "./DispensariesProductsTableCell"

import "../Styles/DispensariesProductsPageStyles/dispensariesProductsTable.scss"

const DispensariesProductsTable = () => {
    const { dispensaryid, locationid, category } = useParams()

    const [dispensary, setDispensary] = useState(null)
    const [products, setProducts] = useState([])

    useEffect(() => {
        getCategoryProducts()
    }, [])

    const getCategoryProducts = async () => {
        const products_response = await getDispensaryCategoryProducts(dispensaryid, category, locationid)
        if (products_response.data != null) {
            setDispensary(products_response.data.outlet || null)
            setProducts(products_response.data.products)
        }
    }

    return (
        <table className="dispensaries-products-table">
            <thead>
                <DispensariesProductsTableHeadCell dispensary={dispensary} category={category} backLocation={`/dispensary/${dispensaryid}/${locationid}`} />
            </thead>
            <tbody>
                { (dispensary != null && products.length > 0) && products.map(product => (
                    <DispensariesProductsTableCell product={product} dispensary={dispensary} dispensaryID={dispensaryid} locationID={locationid} />
                ))}
            </tbody>
        </table>
    )
}

export default DispensariesProductsTable
