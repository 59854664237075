import Moment from "react-moment"

const AdminOutletTransactionCell = ({ transaction }) => {

    const getOrderType = () => {
        return transaction.what === "withdraw" ? "Withdrawl" : transaction.what === "deposit" ? "Deposit" : "Order Number"
    }

    const getOrderValue = () => {
        let displayFormat = "TBD"

        if (transaction.what === "withdraw" || transaction.what === "deposit") {
            if (transaction.onWho === "Order") {
                displayFormat = `Order # - ${transaction.who.order_code || "!Missing!"}`
            }

            if (transaction.onWho === "User") {
                displayFormat = `${transaction.who.first_name} ${transaction.who.last_name}`
            }
        }
        return displayFormat
    }

    return (
        <tr>
            <td>
                <div>
                    <div className="aot-table-inline">
                        <label>{getOrderType()}: <span>{getOrderValue()}</span></label>
                        <label>Date: <span><Moment format={"MM/DD/YY"}>{transaction.when}</Moment></span></label>
                    </div>
                    <div className="aot-table-block">
                        <label>Amount: <span>${transaction.amount.toLocaleString()}</span></label>
                        <label>Remaining Balance: <span>${transaction.new_balance.toLocaleString()}</span></label>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletTransactionCell
