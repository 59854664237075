import { Link } from "react-router-dom"

import "../../Styles/AdminPageStyles/adminTableCreateCell.scss"

const AdminTableCreateCell = ({ createLocation }) => {
    return (
        <tr>
            <td className="admin-table-create-cell">
                <Link to={createLocation}>+</Link>
            </td>
        </tr>
    )
}

export default AdminTableCreateCell
