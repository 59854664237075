import { useState, useEffect, useContext } from "react"
import { useParams, useHistory } from "react-router"
import { CartContext } from "../Utils/CartManager"
import { getDispensaryCategories } from "../Utils/APIManager"
import DispensariesDetailTableHeadCell from "./DispensariesDetailTableHeadCell"
import DispensariesDetailTableCell from "./DispensariesDetailTableCell"

import "../Styles/DispensariesDetailPageStyles/dispensariesDetailTable.scss"

const DispensariesDetailTable = () => {
    let history = useHistory()
    const { resetCart, checkCartLocationAllowed } = useContext(CartContext)
    const { dispensaryid, locationid } = useParams()

    const [dispensary, setDispensary] = useState(null)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategories()

        const locationAllowed = checkCartLocationAllowed(locationid)
        if (!locationAllowed) {
            if (window.confirm("You have items in your cart belonging to another dispensary. You may only order from 1 dispensary at a time. Would you like to clear your cart and order from this dispensary location instead?")) {
                resetCart()
            }else{
                history.push("/")
            }
        }
    }, [])

    const getCategories = async () => {
        const categories_response = await getDispensaryCategories(dispensaryid, locationid)
        if (categories_response.data != null) {
            setDispensary(categories_response.data.outlet || null)
            setCategories(categories_response.data.categories)
        }
    }

    return (
        <table className="dispensaries-detail-table">
            <thead>
                <DispensariesDetailTableHeadCell dispensary={dispensary} />
            </thead>
            <tbody>
                { categories.map(category => (
                    <DispensariesDetailTableCell category={category} />
                ))}
            </tbody>
        </table>
    )
}

export default DispensariesDetailTable
