import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { RoutesContext } from "../Utils/RoutesManager"
import { getDispensaries } from "../Utils/APIManager"
import DispensariesTableHeadCell from "./DispensariesTableHeadCell"
import DispensariesTableCell from "./DispensariesTableCell"

import "../Styles/DispensariesPageStyles/dispensariesTable.scss"

const DispensariesTable = () => {
    let history = useHistory()
    const { baseOrganization, getGoToRouteForOrganization } = useContext(RoutesContext)

    const [dispensaries, setDispensaries] = useState([])

    const area420Org = baseOrganization === "area420"

    useEffect(() => {
        getDispensariesList()
    }, [])

    const getDispensariesList = async () => {
        const dispensaries_response = await getDispensaries("CO", "all")
        if (dispensaries_response.data != null) {

            let filteredDispensaries = dispensaries_response.data.outlets.filter((dispensary) => {

                let availableDispensaryTypes = baseOrganization === "" ? 
                    ["retail-rec", "retail-med"] : ["wholesale"]

                return availableDispensaryTypes.includes(dispensary.outlet_type)
            })

            filteredDispensaries = filteredDispensaries.sort((a, b) => { // Sort By Dispensary Updated At Date
                const dateA = a.updatedAt ? new Date(a.updatedAt) : Date.now()
                const dateB = b.updatedAt ? new Date(b.updatedAt) : Date.now()
        
                return dateB - dateA
            })

            if (baseOrganization === "") {
                setDispensaries(filteredDispensaries)
            }else if (area420Org) {
                skipDispensaryLocationSelection("Area420", filteredDispensaries)
            }
        }
    }

    const skipDispensaryLocationSelection = (org, unsetDispensaries) => {
        for (let i = 0; i < unsetDispensaries.length; i++) {
            const aDispensary = unsetDispensaries[i]
            if (aDispensary.name == org) {
                for (let j = 0; j < aDispensary.locations.length; j++) {
                    const aDispensaryLocation = aDispensary.locations[j]
                    history.push(getGoToRouteForOrganization(`/dispensary/${aDispensary._id}/${aDispensaryLocation.locationId}`))
                }
            }
        }
    }

    const dispensaryLocations = () => {
        let dispensaryLocations = []
        for (let i = 0; i < dispensaries.length; i++) {
            const aDispensary = dispensaries[i]
            for (let j = 0; j < aDispensary.locations.length; j++) {
                const aDispensaryLocation = aDispensary.locations[j]
                dispensaryLocations.push(<DispensariesTableCell dispensary={aDispensary} location={aDispensaryLocation} locationID={aDispensaryLocation.locationId} />)
            }
        }

        return dispensaryLocations
    }

    return (
        <table className="dispensaries-table">
            <thead>
                <DispensariesTableHeadCell />
            </thead>
            <tbody>
                { dispensaryLocations().map(dispensary => (
                    dispensary
                )) }
            </tbody>
        </table>
    )
}

export default DispensariesTable
