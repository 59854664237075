import CartCheckoutTableHeadCell from "../CartPage/CartCheckoutTableHeadCell"
import CheckoutTableCell from "./CheckoutTableCell"

import "../Styles/CartPageStyles/cartCheckoutTable.scss"


const CheckoutTable = ({ props }) => {

    return (
        <table className="cart-checkout-table">
            <thead>
                <CartCheckoutTableHeadCell mode={"checkout"} />
            </thead>

            <tbody>
                <CheckoutTableCell props={props} />
            </tbody>
        </table>
    )
}

export default CheckoutTable
