
import "../Styles/awsImageViewer.scss"

const AWSImageViewer = ({ handleClose, show, imageSrc }) => {
    const showHideClassName = show ? "aws-image-viewer display-block" : "aws-image-viewer display-none"

    return (
        <div className={showHideClassName}>
        <div className="aiv-main">
            <div className="aiv-image">
                <img src={imageSrc}></img>
            </div>
            <div className="aiv-foot">
                <button type="button" onClick={handleClose}>Close</button>
            </div>
        </div>
      </div>
    )
}

export default AWSImageViewer
