import { useState, useContext } from "react"
import { CartContext } from "../Utils/CartManager"
import { dateOnly, phoneOnly } from "../Utils/InputValidators"
import OrderSummary from "../Shared/OrderSummary"
import VerifiedIcon from "../Images/verified.png"

import "../Styles/CartPageStyles/cartCheckoutTableCell.scss"


const CheckoutTableCell = ({ props }) => {
    const { cartDispensary } = useContext(CartContext)

    const { deliveryAddress, deliveryAddress2, setFirstName, setLastName, setBirthdate, setMobilePhone, setPhotoID, setPatientRegistrationNumber, setDispensaryName, setDispensaryLicenseNumber } = props
    const { formErrorMessage, showFormErrorMessage, firstNameInvalid, lastNameInvalid, birthDateInvalid, mobilePhoneInvalid, photoIDInvalid, patientRegistrationNumberInvalid, dispensaryNameInvalid, dispensaryLicenseNumberInvalid } = props

    const [photoIDAttached, setPhotoIDAttached] = useState(false)

    const isRecreationalOutlet = cartDispensary.outlet_type === "retail-rec"
    const isMedicalOutlet = cartDispensary.outlet_type === "retail-med"
    const isWholeSaleOutlet = cartDispensary.outlet_type === "wholesale"

    const handlePhotoID = (e) => {
        setPhotoIDAttached(true)
        setPhotoID(e.target.files[0])
    }

    return (
        <tr className="cart-checkout-table-cell checkout-table-cell">
            <td>
                <div className="cc-section">
                    <label className="cc-section-label">Delivery Address</label>

                    <div className="cc-section-input">
                        <input type="text" value={deliveryAddress || ""} readOnly></input>
                    </div>
                    { deliveryAddress2 &&
                        <div className="cc-section-input">
                            <input type="text" value={deliveryAddress2 || ""} readOnly></input>
                        </div>
                    }
                </div>

                <div className="cc-section">
                    <label className="cc-section-label">Customer Information</label>
                    

                    { showFormErrorMessage &&
                        <div className="cc-section-error" dangerouslySetInnerHTML={{__html: formErrorMessage()}}>
                            
                        </div>
                    }

                    <div className="cc-section-textarea cc-section-textarea-inline">
                        <textarea className={`${(showFormErrorMessage && firstNameInvalid()) ? "cc-section-input-error" : ""}`} onChange={(e) => setFirstName(e.target.value)} placeholder="First name               (on your Photo ID)"></textarea>
                        <textarea className={`${(showFormErrorMessage && lastNameInvalid()) ? "cc-section-input-error" : ""}`} onChange={(e) => setLastName(e.target.value)} placeholder="Last name                (on your Photo ID)"></textarea>
                    </div>
                    <div className="cc-section-textarea cc-section-textarea-inline">
                        <textarea className={`${(showFormErrorMessage && birthDateInvalid()) ? "cc-section-input-error" : ""}`} maxLength={10} inputMode="numeric" onKeyUp={(e) => dateOnly(e, setBirthdate)} placeholder="Birthday       (mm/dd/yyyy)"></textarea>
                        <textarea className={`${(showFormErrorMessage && mobilePhoneInvalid()) ? "cc-section-input-error" : ""}`} maxLength={14} inputMode="numeric" onKeyUp={(e) => phoneOnly(e, setMobilePhone)} placeholder="Mobile Phone         (for order updates)"></textarea>
                    </div>

                    { (cartDispensary != null && isMedicalOutlet) &&
                        <div className="cc-section-textarea">
                            <textarea className={`${(showFormErrorMessage && patientRegistrationNumberInvalid()) ? "cc-section-input-error" : ""}`} onChange={(e) => setPatientRegistrationNumber(e.target.value)} placeholder="Patient registration number"></textarea>
                        </div>
                    }

                    { (cartDispensary != null && isWholeSaleOutlet) &&
                        <div className="cc-section-textarea cc-section-textarea-inline">
                            <textarea className={`${(showFormErrorMessage && dispensaryNameInvalid()) ? "cc-section-input-error" : ""}`} onChange={(e) => setDispensaryName(e.target.value)} placeholder="Dispensary Name"></textarea>
                            <textarea className={`${(showFormErrorMessage && dispensaryLicenseNumberInvalid()) ? "cc-section-input-error" : ""}`} onChange={(e) => setDispensaryLicenseNumber(e.target.value)} placeholder="License Number"></textarea>
                        </div>
                    }
                </div>
                
                { (isRecreationalOutlet || isMedicalOutlet) && 
                    <div className="cc-section-file-input">
                        <label className={`${(showFormErrorMessage && photoIDInvalid()) ? "cc-section-input-error" : ""}`}>
                            <input type="file" accept="image/*" onChange={handlePhotoID}></input>
                            Upload your Photo ID
                        </label>
                        { photoIDAttached && 
                            <img src={VerifiedIcon}></img> 
                        }
                    </div>
                }

                <div className="cc-section">
                    <div>
                        <button type="submit">Place Order</button>
                    </div>
                </div>

                <OrderSummary />
            </td>
        </tr>
    )
}

export default CheckoutTableCell
