import { BrowserRouter as Router } from "react-router-dom"
import { Helmet } from "react-helmet"
import { RoutesProvider } from "./components/Utils/RoutesManager"
import { CartProvider } from "./components/Utils/CartManager"
import { UserProvider } from "./components/Utils/UserManager"

import Routes from "./components/Shared/Routes"


const App = () => {
  
  /* Current URL Admin Route Determination */
  const locationPath = window.location.pathname
  const isAdminRoute = locationPath.includes("myorders")

  const subAppContainerStyle = {
    paddingTop: !isAdminRoute ? "65px" : "unset",
    backgroundColor: !isAdminRoute ? "#38b23a" : "#f2f3f5"
  }

  const setPathname = (pathname) => {
      //console.log("**", pathname)
  }

  const RouteComponent = () => {

        return <RoutesProvider>
                  <UserProvider>
                    <CartProvider>
                        <Router>
                          <Routes setPathname={setPathname} isAdminRoute={isAdminRoute} />
                        </Router>
                    </CartProvider>
                  </UserProvider>
                </RoutesProvider>
  }

  return (
    <div className="main-app-container">
      <div className="sub-app-container" style={subAppContainerStyle}>
        <Helmet>
          <title>Cloud 9 Concierge</title>
          <meta name="description" content="Cannabis delivery" />
          <meta name="apple-mobile-web-app-status-bar-style" content={ !isAdminRoute ? "#38b23a" : "#f2f3f5"} />
          <meta name="theme-color" content={ !isAdminRoute ? "#38b23a" : "#f2f3f5"} />
        </Helmet>

        <RouteComponent />

      </div>
    </div>
  )
}

export default App
