import AdminLogo from "../../components/AdminPages/Shared/AdminLogo"
import AdminControlsTable from "../../components/AdminPages/AdminControlsPage/AdminControlsTable"

import "../../components/Styles/AdminPageStyles/adminControlsPage.scss"

const AdminControlsPage = () => {
    return (
        <div className="admin-controls-page-container">

            <AdminLogo logoText={"Controls"} displayBackBtn={true} backBtnPath={"/myorders/menu"} />
            <AdminControlsTable />

        </div>
    )
}

export default AdminControlsPage
