import { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router"
import { adminGetOutletCredentials, adminEditOutletCredentials } from "../../components/Utils/APIManager"
import { capitalizeFirstLetter } from "../../components/Utils/StringFormatters"
import Moment from "react-moment"
import AdminOutletLogo from "../../components/AdminPages/Shared/AdminOutletLogo"

import "../../components/Styles/AdminPageStyles/adminOutletCredentialsPage.scss"

const AdminOutletCredentialsPage = () => {
    let history = useHistory()
    const { outletid } = useParams()

    const [lastUpdated, setLastUpdated] = useState("--")
    const [outletName, setOutletName] = useState("---")
    const [thirdParty, setThirdParty] = useState("none")

    // Flowhub States
    const [getKey, setGetKey] = useState(null)
    const [secretKey, setSecretKey] = useState(null)
    const [clientID, setClientID] = useState(null)
    const [oAuthClientID, setoAuthClientID] = useState(null)

    // Greenbits States
    const [token, setToken] = useState(null)

    // Biotrack States
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    useEffect(() => {
        getCredentials()
    }, [])

    const getCredentials = async () => {
        const credentials_response = await adminGetOutletCredentials(outletid)
        
        if (credentials_response.data != null) {
            
            const third_party = credentials_response.data.credentials.outlet.third_party
            setThirdParty(third_party)
            setOutletName(credentials_response.data.credentials.outlet.name)
            setLastUpdated(credentials_response.data.updatedAt)

            if (third_party === "flowhub") {
                handleFlowHubCredsStates(credentials_response.data.credentials)
            }

            if (third_party === "greenbits") {
                handleGreenbitsCredsStates(credentials_response.data.credentials)
            }

            if (third_party === "biotrack") {
                handleBiotrackCredsStates(credentials_response.data.credentials)
            }
        }
    }

    const handleFlowHubCredsStates = (credentials) => {
        setGetKey(credentials.flowhub.get_key)
        setSecretKey(credentials.flowhub.secret_key)
        setClientID(credentials.flowhub.client_id)
        setoAuthClientID(credentials.flowhub.oauth_client_id)
    }

    const handleGreenbitsCredsStates = (credentials) => {
        setToken(credentials.greenbits.token)
    }

    const handleBiotrackCredsStates = (credentials) => {
        setUsername(credentials.biotrack.username)
        setPassword(credentials.biotrack.password)
    }

    const editCredentials = async () => {
        if (thirdParty === "none") {
            return
        }

        let sendData = null

        // Flowhub Form Validation
        if (thirdParty === "flowhub") {
            if (getKey === null || getKey.trim().length === 0) {
                alert("GET Key is Required")
                return
            }
    
            if (secretKey === null || secretKey.trim().length === 0) {
                alert("Secret Key is Required")
                return
            }
    
            if (clientID === null || clientID.trim().length === 0) {
                alert("Client ID is Required")
                return
            }
    
            if (oAuthClientID === null || oAuthClientID.trim().length === 0) {
                alert("oAuth Client ID is Required")
                return
            }

            sendData = {
                "credentials": {
                    "credType": thirdParty,
                    "flowhub": {
                        "get_key": getKey,
                        "secret_key": secretKey,
                        "client_id": clientID,
                        "oauth_client_id": oAuthClientID
                    }
                }
            }
        }

        // Greenbits Form Validation
        if (thirdParty === "greenbits") {

            if (token === null || token.trim().length === 0) {
                alert("Token is Required")
                return
            }

            sendData = {
                "credentials": {
                    "credType": thirdParty,
                    "greenbits": {
                        "token": token
                    }
                }
            }
        }

        // Biotrack Form Validation
        if (thirdParty === "biotrack") {

            if (username === null || username.trim().length === 0) {
                alert("Username is Required")
                return
            }

            if (password === null || password.trim().length === 0) {
                alert("Password is Required")
                return
            }

            sendData = {
                "credentials" : {
                    "credType": thirdParty,
                    "biotrack": {
                        "username": username,
                        "password": password
                    }
                }
            }
        }

        if (sendData != null) {
            const credentials_edit_response = await adminEditOutletCredentials(outletid, sendData)
            if (credentials_edit_response.data != null) {
                alert("Credentials have been Successfully Updated")
                history.go(0)
            }
        }else{
            alert("Please setup an appropriate third party PoS when editing the outlet")
        }
    }

    return thirdParty != "none" ?
    (
        <div className="admin-outlet-credentials-page-container">

            <AdminOutletLogo outletid={outletid} type={"credentials"} outletName={outletName} />
            

            <div className="aoc-section">
                <label>Manage {capitalizeFirstLetter(thirdParty)} Credentials:</label>

                <div className="aoc-section-sub">
                    <label>Last Updated: <Moment format={"MM/DD/YY @ hh:mma"}>{lastUpdated}</Moment></label>
                </div>

                { thirdParty === "flowhub" &&
                    <div className="aoc-inputs-container">

                        <div>
                            <label>GET Key:</label>
                            <input type="text" placeholder={"GET Value"} value={getKey} onChange={(e) => setGetKey(e.target.value)}></input>
                        </div>

                        <div>
                            <label>Secret Key:</label>
                            <input type="text" placeholder={"Secret Value"} value={secretKey} onChange={(e) => setSecretKey(e.target.value)}></input>
                        </div>

                        <div>
                            <label>Client ID:</label>
                            <input type="text" placeholder={"Client Value"} value={clientID} onChange={(e) => setClientID(e.target.value)}></input>
                        </div>

                        <div>
                            <label>oAuth Client ID:</label>
                            <input type="text" placeholder={"oAuth Client Value"} value={oAuthClientID} onChange={(e) => setoAuthClientID(e.target.value)}></input>
                        </div>

                        <button onClick={() => editCredentials()}>Update Credentials</button>
                    </div>
                }


                { thirdParty === "greenbits" &&
                    <div className="aoc-inputs-container">
                        <div>
                            <label>Token:</label>
                            <input type="text" placeholder={"Token Value"} value={token} onChange={(e) => setToken(e.target.value)}></input>
                        </div> 

                        <button onClick={() => editCredentials()}>Update Credentials</button>
                    </div>
                }

                { thirdParty === "biotrack" &&
                    <div className="aoc-inputs-container">
                        <div>
                            <label>Username:</label>
                            <input type="text" placeholder={"Username Value"} value={username} onChange={(e) => setUsername(e.target.value)}></input>
                        </div>

                        <div>
                            <label>Password:</label>
                            <input type="text" placeholder={"Password Value"} value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        </div>

                        <button onClick={() => editCredentials()}>Update Credentials</button>
                    </div>
                }
            </div>
        </div>
    ) :
    (
        <div>
            <div className="admin-outlet-credentials-page-container">

                <AdminOutletLogo outletid={outletid} type={"credentials"} outletName={outletName} />

                <div className="aoc-section">
                    <label style={{textAlign: "center"}}>No Third Party PoS Selected for this Outlet</label>
                </div>
            </div>
        </div>
    )
}

export default AdminOutletCredentialsPage
