import { useEffect, useState, useContext, Fragment } from "react"
import { Route, Switch, Redirect, useLocation } from "react-router-dom"

import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"

import ScrollToTop from "../../components/Utils/ScrollToTop"

import AdminRoute from "../../components/Shared/AdminRoute"
import Header from "../../components/Shared/Header"

import NotFoundPage from "../../pages/404"
import LandingPage from "../../pages/LandingPage"
import AboutPage from "../../pages/AboutPage"

import DispensariesPage from "../../pages/DispensariesPage"
import DispensariesDetailPage from "../../pages/DispensariesDetailPage"
import DispensariesProductsPage from "../../pages/DispensariesProductsPage"
import CartPage from "../../pages/CartPage"
import CheckoutPage from "../../pages/CheckoutPage"
import OrderStatusPage from "../../pages/OrderStatusPage"

import AdminLandingPage from "../../pages/AdminPages/AdminLandingPage"
import AdminHelpPage from "../../pages/AdminPages/AdminHelpPage"
import AdminMenuPage from "../../pages/AdminPages/AdminMenuPage"
import AdminUsersPage from "../../pages/AdminPages/AdminUsersPage"
import AdminOutletsPage from "../../pages/AdminPages/AdminOutletsPage"
import AdminOutletTransactionPage from "../../pages/AdminPages/AdminOutletTransactionPage"
import AdminOutletCredentialsPage from "../../pages/AdminPages/AdminOutletCredentialsPage"
import AdminOutletInvetoryPage from "../../pages/AdminPages/AdminOutletInvetoryPage"
import AdminControlsPage from "../../pages/AdminPages/AdminControlsPage"
import AdminOrdersPage from "../../pages/AdminPages/AdminOrdersPage"

function Routes({ setPathname, isAdminRoute }) {
    const { 
        baseOrganization,
        handleSetBaseOrganization,
        landingPaths,
        aboutPaths, 
        dispensaryDetailPaths,
        dispensaryProductPaths,
        cartPaths,
        checkoutPaths,
        orderConfirmationPaths
    } = useContext(RoutesContext)

    const { resetCart } = useContext(CartContext)
    const location = useLocation()

    const [landingComponentAccepted, setLandingComponentAccepted] = useState(false)

    useEffect(() => {
        //console.log("*", location.pathname)

        const noOrg = ""
        const area420 = "area420"


        if (location.pathname.includes(area420)) {
            if (!baseOrganization.includes(area420)) {
                resetCart() // Wipe Cart on Any New Org Change
                handleSetBaseOrganization(area420)
            }
        }else{

            // Previous Org Wasn't C9, Wipe Cart
            if (baseOrganization.includes(area420)) {
                resetCart()
            }

            handleSetBaseOrganization(noOrg)
        }

        setPathname(location.pathname)
    }, [location])

    const headerProps = {
        showLogo: landingComponentAccepted,
        showCart: landingComponentAccepted,
        baseOrganization: baseOrganization
    }

    const landingComponentProps = {
        setLandingComponentAccepted: setLandingComponentAccepted,
        baseOrganization: baseOrganization
    }

    return (
        <>
            { !isAdminRoute &&
                <Header props={headerProps} />
            }

            <Fragment>
                <ScrollToTop />

                { (!landingComponentAccepted && !isAdminRoute) ?
                
                    <Route render={() => (
                        <LandingPage props={landingComponentProps} />
                    )} />

                :
                    <Switch>

                        { /* User Routes */}
                        <Route exact path={landingPaths} component={DispensariesPage} />
                        <Route exact path={dispensaryDetailPaths} component={DispensariesDetailPage} />
                        <Route exact path={dispensaryProductPaths} component={DispensariesProductsPage} />
                        <Route exact path={cartPaths} component={CartPage} />
                        <Route exact path={checkoutPaths} component={CheckoutPage} />
                        <Route exact path={orderConfirmationPaths} component={OrderStatusPage} />

                        <Route exact path={aboutPaths} component={AboutPage} />

                        {/* Admin Routes */}
                        <Route exact path="/myorders" component={AdminLandingPage} />
                        <Route exact path="/myorders/help" component={AdminHelpPage} />
                        
                        <AdminRoute path="/myorders/menu" render={<AdminMenuPage />} />

                        <AdminRoute path="/myorders/orders" render={<AdminOrdersPage />} />

                        <AdminRoute path="/myorders/users/create" render={<AdminUsersPage mode={"create"} />} />
                        <AdminRoute path="/myorders/users/:userid" render={<AdminUsersPage mode={"edit"} />} />
                        <AdminRoute path="/myorders/users" render={<AdminUsersPage mode={"view"} />} />
                        

                        <AdminRoute path="/myorders/outlets/:outletid/transactions" render={<AdminOutletTransactionPage />} />
                        <AdminRoute path="/myorders/outlets/:outletid/credentials" render={<AdminOutletCredentialsPage />} />

                        <AdminRoute path="/myorders/outlets/:outletid/inventory/create" render={<AdminOutletInvetoryPage mode={"create"} />} />
                        <AdminRoute path="/myorders/outlets/:outletid/inventory/:productid" render={<AdminOutletInvetoryPage mode={"edit"} />} />
                        <AdminRoute path="/myorders/outlets/:outletid/inventory" render={<AdminOutletInvetoryPage mode={"view"} />} />

                        <AdminRoute path="/myorders/outlets/create" render={<AdminOutletsPage mode={"create"} />} />
                        <AdminRoute path="/myorders/outlets/:outletid" render={<AdminOutletsPage mode={"edit"} />} />
                        <AdminRoute path="/myorders/outlets" render={<AdminOutletsPage mode={"view"} />} />


                        



                        <AdminRoute path="/myorders/controls" render={<AdminControlsPage />} />

                        {/* Temp Lemonnade Redirect to Dispensary Route */}
                        <Route exact path="/LemonnadeDenver" render={() => (
                            <Redirect to="/dispensary/624d2d34d461430003035fe1/310" />
                        )} />
                        <Route exact path="/Lemonnade" render={() => (
                            <Redirect to="/dispensary/624d2d34d461430003035fe1/310" />
                        )} />
                        <Route exact path="/BigWeedCo" render={() => (
                            <Redirect to="/dispensary/6438791534134000027e1aac/0" />
                        )} />
			<Route exact path="/bigweedco" render={() => (
                            <Redirect to="/dispensary/6438791534134000027e1aac/0" />
                        )} />
                        <Route exact path="/americannex" render={() => (
                            <Redirect to="/dispensary/642849695847ae0002937e26/0" />
                        )} />
                        <Route exact path="/Americannex" render={() => (
                            <Redirect to="/dispensary/642849695847ae0002937e26/0" />
                        )} />


                        <Route exact path="/404" component={NotFoundPage} />
                        <Redirect to="/404" />

                    </Switch>
                }
            </Fragment>
        </>
    );
}

export default Routes
