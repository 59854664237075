
export const stringToPhoneFormat = (phoneString, useIntlCode) => {
    // Filter only numbers from the phoneString
    let cleaned = ('' + phoneString).replace(/\D/g, '');
    // Check if the phoneString is of correct length
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {

        if (useIntlCode) {
            let intlCode = (match[1] ? '+1 ' : '') // Change this to format for any country code.
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }else{
            return ['(', match[2], ') ', match[3], '-', match[4]].join('')
        }

    }
    return phoneString
}

export const stringToPhoneDataFormat = (phoneString) => {
    return `+1${(phoneString).replace(/\D/g, "")}`
}

export const dispensaryLocationFormat = (location) => {
    let displayFormat = ""
    if (location && location.street && location.city && location.state && location.zipcode) {
        displayFormat = `${location.street}, ${location.city}, ${location.state} ${location.zipcode}`

        const words = displayFormat.split(" ")
        for (let i = 0; i < words.length; i++) {
            if (words[i].length === 2) {
                // Capitalize State Word
                words[i] = words[i].toUpperCase()
            }else{
                words[i] = words[i][0].toUpperCase() + words[i].substr(1)
            }
            
        }

        displayFormat = words.join(" ")
    }

    return displayFormat
}

export const timeInputToLocaleFormat = (time) => {
    const [h, m] = time.split(":");
    return `${(h % 12 + 12 * (h % 12==0)) + ":" + m} ${h >= 12 ? "PM" : "AM"}`
}

export const dollarsToCents = (dollars) => {
    return (parseFloat(dollars) * 100).toString();
}

export const centsToDollars = (cents) => {
    const dollars = parseFloat(cents / 100)
    return dollars
}

export const centsToDollarsDisplay = (cents) => {
    const dollars = parseFloat(cents / 100)
    return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"})
}

export const categoryDisplayFormat = (category) => {
    let displayFormat = category
    switch (category) {
        case "Accessory":
            displayFormat = "Accessories"
            break
        case "Concentrate":
            displayFormat = "Concentrates"
            break
        case "Edible":
            displayFormat = "Edibles"
            break
        case "NonEdible":
            displayFormat = "Topicals"
            break
        case "Joint":
            displayFormat = "Pre-Rolls"
            break
        case "Extract":
            displayFormat = "Extracts"
            break
        case "Seed":
            displayFormat = "Seeds"
            break
        case "PackedBud":
            displayFormat = "Prepacked Flower"
            break
	case "BulkBud":
	    displayFormat = "Bulk Flower"
	    break
        default:
            displayFormat = category
    }

    return displayFormat
}

export const productStatRangeDisplay = (type, product) => {
    let displayFormat = ""
    for (let i = 0; i < product.cannabinoidInformation.length; i++) {
        const information = product.cannabinoidInformation[i]
        if (information.name && information.name === type) {
            if (information.lowerRange != null && information.upperRange != null) {
                displayFormat = `${information.lowerRange}% - ${information.upperRange}%`
            }else if (information.lowerRange != null && information.upperRange === null) {
                displayFormat =  `${information.lowerRange}%`
            }else if (information.lowerRange === null && information.upperRange != null) {
                displayFormat =  `${information.upperRange}%`
            }
        }
    }

    return displayFormat
}

export const productSpeciesDisplay = (productSpecies) => {
    let displayFormat = ""
    switch (productSpecies) {
        case "50/50":
            displayFormat = "Hybrid"
            break
        default:
            displayFormat = productSpecies
    }

    return displayFormat
}

export const gramsToPounds = (gramsAmount) => {
    return parseFloat(gramsAmount*0.0022046)
}

export const poundsToGrams = (poundsAmount) => {
    return parseFloat(poundsAmount/0.0022046)
}

export const productGramsDisplay = (gramAmount) => {
    let displayFormat = gramAmount
    switch (gramAmount) {
        case 1:
            displayFormat = "1g"
            break
        case 3.5:
            displayFormat = "1/8 oz"
            break
        case 7:
            displayFormat = "1/4 oz"
            break
        case 14:
            displayFormat = "1/2 oz"
            break
        case 28:
            displayFormat = "1oz"
            break
        case 448:
            displayFormat = "1lb"
            break
        default:
            displayFormat = `${gramAmount}g`
    }

    return displayFormat
}

export const productPriceDisplay = (unitPriceInCents) => {
    let dollars = unitPriceInCents / 100
    dollars = dollars.toLocaleString("en-US", {style:"currency", currency:"USD"}).replace(/\.00$/, "") // Remove the .00 if applicable
    return dollars
}

export const orderStatusDisplay = (status) => {
    let displayFormat = status
    switch (status) {
        case "inQueue":
            displayFormat = "In Queue"
            break
        case "released":
            displayFormat = "Released"
            break
        case "inTransit":
            displayFormat = "Out for Delivery"
            break
        case "delivered":
            displayFormat = "Delivered"
            break
        default:
            displayFormat = status
    }

    return displayFormat
}

export const getDriverUserDisplayByID = (driverUsers, driverId, defaultDisplay) => {
    if (driverUsers === null || driverUsers === undefined) {
        return 
    }

    let driverDisplay = defaultDisplay
    for (let i = 0; i < driverUsers.length; i++) {
        const aUser = driverUsers[i]
        if (aUser._id === driverId) {
            driverDisplay = `${aUser.first_name} ${aUser.last_name}`
            break
        }
    }

    return driverDisplay
}

export const getBudtenderUserDisplayByID = (budtenderUsers, budtenderId, defaultDisplay) => {
    if (budtenderUsers === null || budtenderUsers === undefined) {
        return 
    }

    let budtenderDisplay = defaultDisplay
    for (let i = 0; i < budtenderUsers.length; i++) {
        const aUser = budtenderUsers[i]
        if (aUser._id === budtenderId) {
            budtenderDisplay = `${aUser.first_name} ${aUser.last_name}`
            break
        }
    }

    return budtenderDisplay
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatAddressHash = (address) => {
    let formattedAddress = ""

    if (address.street2 === undefined || address.street2.length === 0) {
        formattedAddress = `${address.street1}, ${address.city}, ${address.state} ${address.zip}`
    }else{
        formattedAddress = `${address.street1} ${address.street2}, ${address.city}, ${address.state} ${address.zip}`
    }

    return formattedAddress
}

export const formatAddressHashForURLQuery = (address) => {
    return `http://maps.apple.com/?q=${address.street1}+${address.city}+${address.state}+${address.zip}`
}
