import { useHistory } from "react-router"

const AdminOutletInventoryTableCell = ({ product, locations, inventory }) => {
    let history = useHistory()

    const getLocationForProduct = () => {
        let locationString = null
        if (locations != null) {
            for (let i = 0; i < locations.length; i++) {
                const aLocation = locations[i]
                if (aLocation.locationId === product.locationId) {
                    locationString = `${aLocation.street}...`
                }
            }
        }

        return locationString
    }

    const adminOutletInventoryTableCellClicked = () => {
        if (product._id) {
            history.push(`/myorders/outlets/${inventory.outlet._id}/inventory/${product._id}`)
        }
    }

    return (
        <tr onClick={adminOutletInventoryTableCellClicked}>
            <td className={product.display ? "admin-outlets-inventory-table-cell" : "admin-outlets-inventory-table-cell aoi-tc-undisplayed"}>

                <div>
                    <label>{product.productName}</label>
                    { getLocationForProduct() != null &&
                        <span>{getLocationForProduct()}</span>
                    }
                </div>
            </td>
        </tr>
    )
}

export default AdminOutletInventoryTableCell
