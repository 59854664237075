import { useContext } from "react"
import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"
import { productStatRangeDisplay, productSpeciesDisplay, productGramsDisplay, productPriceDisplay } from "../Utils/StringFormatters"
import AWSImage from "../Shared/AWSImage"

import LogoSmall from "../Images/logo-small.png"

import "../Styles/DispensariesProductsPageStyles/dispensariesProductsTableCell.scss"
import Moment from "react-moment"

const DispensariesProductsTableCell = ({ product, dispensary, dispensaryID, locationID }) => {
    const { baseOrganization } = useContext(RoutesContext)
    const { cartItems, getCartItem, addItemToCart, removeItemFromCart } = useContext(CartContext)

    const categoryImageFileKey = `categories/${product.category.toLowerCase()}.png`
    
    const DPSingleSelect = ({ product }) => {

        const determineAllowCartItemSingleAdd = () => {
            // Overall Logic when adding an Each Item
            // - Must Be in Stock
            // - Total Grams of Flower in Cart cannot be 28 (oz) if on C9 Org

            let totalGramAmount = 0
            for (let i = 0; i < cartItems.length; i++) {
                const item = cartItems[i]

                for (let j = 0; j < item.quantity; j++) {
                    // Find Total Gram Amount against all Items
                    if (item.product != null && item.gramAmount != null) {
                        totalGramAmount = totalGramAmount + item.gramAmount
                    }
                }
            }

            const totalGramLimitPerOrder = baseOrganization === "" ? 28 : 99999
            const allowedGramLimit = totalGramAmount < totalGramLimitPerOrder ? true : false
            const inStock = cartItem.quantity < product.quantity ? true : false

            return (allowedGramLimit && inStock) ? true : false
        }

        let cartItem = getCartItem(product._id)
        cartItem["product"] = product
        cartItem["productPrice"] = product.priceInMinorUnits

        const cartItemQuantity = cartItem.quantity
        const showCartItemQuantity = cartItemQuantity > 0
        const allowCartItemAdd = determineAllowCartItemSingleAdd()
        const allowCartItemRemove = cartItemQuantity > 0

        return <div className="dp-selection dp-single-selection">
                    <div className="dp-selection-control">
                        <label>
                            <span>{ productPriceDisplay(product.priceInMinorUnits) }</span>
                        </label>
                        { allowCartItemAdd &&
                            <div className="dp-selection-cart-status dp-cart-add" onClick={() => {addItemToCart({id: cartItem.id, quantity: cartItem.quantity + 1}, dispensary, dispensaryID, locationID)}}>
                                +
                            </div>
                        }
                        { allowCartItemRemove &&
                            <div className="dp-selection-cart-status dp-cart-remove" onClick={() => {removeItemFromCart({id: cartItem.id, quantity: cartItem.quantity - 1})}}>
                                -
                            </div>
                        }
                    </div>
                    { showCartItemQuantity &&
                        <div className="dp-selection-quantity">
                            <label>Qty: {cartItemQuantity}</label>
                        </div>
                    }
                </div>
    }

    const DpSelection = ({ product, weightTier }) => {

        const determineAllowCartItemAdd = () => {
            // Overall Logic when adding an Weight Tier Item
            // - Must Be in Stock (currentGramAmount)
            // - Total Grams of Flower in Cart cannot be 28 (oz) if on C9 Org (TotalGramAmount)

            let currentGramAmount = 0
            let totalGramAmount = 0
            for (let i = 0; i < cartItems.length; i++) {
                const item = cartItems[i]

                for (let j = 0; j < item.quantity; j++) {
                    // Find Total Gram Amount against all Items
                    if (item.product != null && item.gramAmount != null) {
                        totalGramAmount = totalGramAmount + item.gramAmount
                    }

                    // Find Current Gram Amount For Specific Item
                    if (item.product != null && item.product._id === product._id) {
                        if (item.gramAmount != null) {
                            currentGramAmount = currentGramAmount + item.gramAmount
                        }
                    }
                }
            }

            const totalGramLimitPerOrder = baseOrganization === "" ? 28 : 99999
            const allowedGramLimit = totalGramAmount < totalGramLimitPerOrder ? true : false
            const inStock = currentGramAmount <= (product.quantity - weightTier.gramAmount) ? true : false

            return (allowedGramLimit && inStock) ? true : false
        }

        let cartItem = getCartItem(`${product._id}_${weightTier.gramAmount}g`)
        cartItem["product"] = product
        cartItem["productPrice"] = weightTier.pricePerUnitInMinorUnits
        cartItem["gramAmount"] = weightTier.gramAmount != null ? weightTier.gramAmount : null

        const cartItemQuantity = cartItem.quantity
        const showCartItemQuantity = cartItemQuantity > 0
        const allowCartItemAdd = determineAllowCartItemAdd()
        const allowCartItemRemove = cartItemQuantity > 0

        return  <div className="dp-selection">
                    <div className="dp-selection-control">
                        <label>
                            { productGramsDisplay(weightTier.gramAmount) }
                            <br></br>
                            <span>{ productPriceDisplay(weightTier.pricePerUnitInMinorUnits) }</span>
                        </label>
                        { allowCartItemAdd &&
                            <div className="dp-selection-cart-status dp-cart-add" onClick={() => {addItemToCart({id: cartItem.id, quantity: cartItem.quantity + 1}, dispensary, dispensaryID, locationID)}}>
                                +
                            </div>
                        }
                        { allowCartItemRemove &&
                            <div className="dp-selection-cart-status dp-cart-remove" onClick={() => {removeItemFromCart({id: cartItem.id, quantity: cartItem.quantity - 1})}}>
                                -
                            </div>     
                        }
                    </div>
                    { showCartItemQuantity &&
                        <div className="dp-selection-quantity">
                            <label>Qty: {cartItemQuantity}</label>
                        </div>
                    }
                </div>
    }

    return (
        <tr className="dispensaries-products-table-cell" key={product._id}>
            <td>
                <div className="dp-info-container">
                    <label>
                        { product.productName }
                        
                        { product.brand != null && <br></br> }
                        { product.brand }
                        
                        <br></br>
                        { productSpeciesDisplay(product.speciesName) }
                        <br></br>
                        {productStatRangeDisplay("thc", product).length > 0 && "THC:"} { productStatRangeDisplay("thc", product) }
                        <br></br>
                        {productStatRangeDisplay("cbd", product).length > 0 && "CBD:"} { productStatRangeDisplay("cbd", product) }
                        <br></br>
                        {productStatRangeDisplay("terpenes", product).length > 0 && "Terpenes:"} { productStatRangeDisplay("terpenes", product) }
                        <br></br>
                        {product.production_date != null &&
                            <span>Harvest: <Moment format="MM/DD/YYYY">{product.production_date}</Moment></span>
                        }
                    </label>

                    {/* Image Available from POS */}
                    { ((product.images === null || product.images.length === 0) && (product.productPictureURL != null || product.productPictureURL != undefined)) &&
                        <div className="dp-images-container">
                            <AWSImage imageURL={product.productPictureURL} enableImageViewer={true}></AWSImage>
                        </div>
                    }

                    {/* Image Default to Category */}
                    { ((product.images === null || product.images.length === 0) && (product.productPictureURL === null || product.productPictureURL === undefined)) &&
                        <div className="dp-images-container">
                            <AWSImage fileKey={categoryImageFileKey} enableImageViewer={true}></AWSImage>
                        </div>
                    }

                    {/* Image Available from Admin */}
                    { (product.images != null && product.images.length > 0) &&
                        <div className="dp-images-container">
                            { product.images.map(image => (
                                <AWSImage fileKey={image.path} enableImageViewer={true}></AWSImage>
                            )) }
                        </div>
                    }
                    
                </div>
                <div className="dp-selections-container">
                    { product.weightTierInformation.sort((a, b) => {
                        return a.gramAmount - b.gramAmount
                    }).map(weightTier => (
                        <DpSelection product={product} weightTier={weightTier} />
                    ))}

                    { product.weightTierInformation.length === 0 &&
                        <DPSingleSelect product={product} />
                    }
                </div>
                <hr></hr>
            </td>
        </tr>
    )
}

export default DispensariesProductsTableCell
