import { useState, useContext } from "react"
import { Route, Redirect } from "react-router"
import { UserContext } from "../Utils/UserManager"

const AdminRoute = ({ path, render }) => {
    const { user, token, getUserRouteAccess } = useContext(UserContext)
    const hasRoleAccess = (user != null && token != null && getUserRouteAccess(path))

    return (
        <Route exact path={path} key={document.location.href} render={() => (hasRoleAccess) ? (render) : (<Redirect to="/myorders" />)} />
    )
}

export default AdminRoute
