import AdminControlsTableCell from "./AdminControlsTableCell"

import "../../Styles/AdminPageStyles/adminControlsTable.scss"

const AdminControlsTable = () => {
    return (
        <form>
            <table className="admin-controls-table">
                <tbody>
                    <AdminControlsTableCell />
                </tbody>
            </table>
        </form>
    )
}

export default AdminControlsTable
