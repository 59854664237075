import { useContext } from "react"
import { useHistory, useParams } from "react-router-dom"
import { categoryDisplayFormat } from "../Utils/StringFormatters"
import { RoutesContext } from "../Utils/RoutesManager"
import AWSImage from "../Shared/AWSImage"
import LogoSmall from "../Images/logo-small.png"

import "../Styles/DispensariesDetailPageStyles/dispensariesDetailTableCell.scss"

const DispensariesDetailTableCell = ({ category }) => {
    const { getGoToRouteForOrganization } = useContext(RoutesContext)
    const { dispensaryid, locationid } = useParams()
    let history = useHistory()

    const dispensariesDetailTableCellClicked = () => {
        history.push(getGoToRouteForOrganization(`/dispensary/${dispensaryid}/${locationid}/${category}`))
    }

    const categoryImageFileKey = `categories/${category.toLowerCase()}.png`

    return (
        <tr className="dispensaries-detail-table-cell" key={`${dispensaryid}-${locationid}-${category}`}>
            <td onClick={dispensariesDetailTableCellClicked}>
                <AWSImage fileKey={categoryImageFileKey} fallbackImage={LogoSmall} />
                <label>{ categoryDisplayFormat(category) }</label>
            </td>
        </tr>
    )
}

export default DispensariesDetailTableCell
