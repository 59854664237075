import { useHistory } from "react-router"
import "../../Styles/AdminPageStyles/adminOutletLogo.scss"

const AdminOutletLogo = ({ outletid, type, outletBalance, outletName }) => {
    let history = useHistory()

    const backBtnClicked = () => {
        history.push(`/myorders/outlets/${outletid}`)
    }

    const aolDescLabel = type === "balance" ? "Balance & Transactions" : type === "credentials" ? "Credentials" : ""

    return (
        <div className="admin-outlet-logo">
            <label className="aol-head">{outletName}</label>
            <label className="aol-desc">{aolDescLabel}</label>

            { type === "balance" &&
                <label className="aol-balance">Current Balance: <span>${outletBalance.toLocaleString()}</span></label>
            }

            <div>
                <button onClick={() => backBtnClicked()}>{"< Back"}</button>
            </div>
        </div>
    )
}

export default AdminOutletLogo
