import { useState, useContext } from "react"
import { Link } from "react-router-dom"
import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"

import LogoSmall from "../Images/logo-small.png"
import Area420Logo from "../Images/area420-logo.png"

import "../Styles/sideNav.scss"

const Header = ({props}) => {
    const { getGoToRouteForOrganization } = useContext(RoutesContext)
    const { cartItemCount } = useContext(CartContext)

    const { showLogo, showCart, baseOrganization } = props
    const [sideNavStyle, setSideNavStyle] = useState({width: "0px"})

    const area420Org = baseOrganization === "area420"

    const openSideNav = () => {
        setSideNavStyle({width: "250px"})
    }
  
    const closeSideNav = () => {
        setSideNavStyle({width: "0px"})
    }

    return (
        <header>
            <div className="sidenav" style={sideNavStyle}>
                <a href="#" className="closebtn" onClick={(e) => {e.preventDefault(); closeSideNav(); return false;}}>&times;</a>\

                <div className="sidenav-option">
                    <img src={LogoSmall}></img>
                    <Link to={getGoToRouteForOrganization(``)} onClick={closeSideNav}>
                        { !area420Org ? "Dispensaries" : "Categories" }
                    </Link>
                </div>

                <div className="sidenav-footer-container">
                    <Link to={getGoToRouteForOrganization(`/about`)} onClick={closeSideNav}>
                        {"About Us"}
                    </Link>
                    {/* <Link to={getGoToRouteForOrganization(``)} onClick={closeSideNav}>
                        {"Terms / AUP / Privacy"}
                    </Link>
                    <div className="sidenav-footer-container-spread">
                        <Link to={getGoToRouteForOrganization(``)} onClick={closeSideNav}>
                            Contact Us
                        </Link>
                        <Link to={getGoToRouteForOrganization(``)} onClick={closeSideNav}>
                            About Us
                        </Link>
                    </div> */}
                </div>
            </div>


            <div className="header-container">

                <div onClick={openSideNav}>
                    <a href="#">MENU</a>
                </div>
                
                { showLogo &&
                    <div>
                        <Link to={getGoToRouteForOrganization(``)}>
                            <img className="logo" src={!area420Org ? LogoSmall : Area420Logo}></img>
                        </Link>
                    </div>
                    
                }

                { showCart && 
                    <div>
                        <Link to={getGoToRouteForOrganization(`/cart`)}>CART</Link>
                        { cartItemCount > 0 &&
                            <div className="cart-quantity-circle">{cartItemCount}</div>
                        }
                    </div>
                }

            </div>
        </header>
    )
}

export default Header
