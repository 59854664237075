import { axiosClient } from "./APIClient"

/* User Requests */
export const userLogin = async (sendData) => {
    return axiosClient.post("/auth/login", sendData)
}
export const userRequestSMS = async (emailParam) => {
    return axiosClient.get(`/help/request?email=${emailParam}`)
}
export const userUpdatePassword = async (sendData) => {
    return axiosClient.patch("/help/update-password", sendData)
}

/* Customer Requests */
export const getDispensaries = async (stateParam, cityParam) => {
    return axiosClient.get(`/outlets?state=${stateParam}&city=${cityParam}`)
}
export const getDispensaryCategories = async (outletParam, locationParam) => {
    return axiosClient.get(`/outlets/${outletParam}/categories?locationId=${locationParam}`)
}
export const getDispensaryCategoryProducts = async (outletParam, categoryParam, locationParam) => {
    return axiosClient.get(`/outlets/${outletParam}/products?category=${categoryParam}&locationId=${locationParam}`)
}
export const cartCheckout = async (sendData) => {
    return axiosClient.post("/checkout", sendData)
}
export const canCheckout = async (sendData) => {
    return axiosClient.patch("/checkout", sendData)
}


/* Admin Users Requests */
export const adminAddUser = async (sendData) => {
    return axiosClient.post("/admin/users", sendData)
}
export const adminEditUser = async (userParam, sendData) => {
    return axiosClient.patch(`/admin/users/${userParam}`, sendData)
}
export const adminGetUser = async (userParam) => {
    return axiosClient.get(`/admin/users/${userParam}`)
}
export const adminGetUsers = async () => {
    return axiosClient.get("/admin/users?role=all")
}
export const adminGetDriverUsers = async () => {
    return axiosClient.get("/admin/users?role=driver")
}
export const adminGetBudtenderUsers = async () => {
    return axiosClient.get("/admin/users?role=outlet")
}
export const adminSuspendUserAccess = async (userParam) => {
    return axiosClient.patch(`/admin/users/${userParam}/suspend`)
}
export const adminUnsuspendUserAccess = async (userParam) => {
    return axiosClient.patch(`/admin/users/${userParam}/unsuspend`)
}
export const adminChangeUserRole = async (sendData) => {
    return axiosClient.patch("/admin/users/change-role", sendData)
}
export const adminGetUserRoles = async () => {
    return axiosClient.get("/admin/roles")
}
export const adminGetUserOutlet = async () => {
    return axiosClient.get("/budtender/outlets")
}

/* Admin Outlets Requests */
export const adminAddOutlet = async (sendData) => {
    return axiosClient.post("/admin/outlets", sendData)
}
export const adminEditOutlet = async (outletParam, sendData) => {
    return axiosClient.patch(`/admin/outlets/${outletParam}`, sendData)
}
export const adminGetOutlet = async (outletParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}`)
}
export const adminGetOutlets = async () => {
    return axiosClient.get("/admin/outlets")
}
export const adminPublishOutlet = async (outletParam) => {
    return axiosClient.patch(`/admin/outlets/${outletParam}/publish`)
}
export const adminUnPublishOutlet = async (outletParam) => {
    return axiosClient.patch(`/admin/outlets/${outletParam}/unpublish`)
}
export const adminSyncOutletLocations = async (outletParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}/sync`)
}
export const adminSyncOutletInventory = async (outletParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}/inventory/sync`)
}

/* Admin Outlet Balance/Transactions Requests */
export const adminGetOutletBalance = async (outletParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}/balance`)
}
export const adminEditOutletBalance = async (outletParam, sendData) => {
    return axiosClient.patch(`/admin/outlets/${outletParam}/balance`, sendData)
}

/* Admin Outlet Credentials Requests */
export const adminGetOutletCredentials = async (outletParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}/credentials`)
}
export const adminEditOutletCredentials = async (outletParam, sendData) => {
    return axiosClient.patch(`/admin/outlets/${outletParam}/credentials`, sendData)
}

/* Admin Outlet Inventory Requests */
export const adminGetOutletInventory = async (outletParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}/inventory`)
}
export const adminGetOutletInventoryProduct = async (outletParam, productParam) => {
    return axiosClient.get(`/admin/outlets/${outletParam}/inventory?productId=${productParam}`)
}
export const adminAddOutletInventoryProduct = async (outletParam, sendData) => {
    return axiosClient.post(`/admin/outlets/${outletParam}/inventory`, sendData)
}
export const adminEditOutletInventoryProduct = async (outletParam, sendData) => {
    return axiosClient.patch(`/admin/outlets/${outletParam}/inventory`, sendData)
}
export const adminDeleteOutletInventoryProduct = async (outletParam, sendData) => {
    return axiosClient.delete(`/admin/outlets/${outletParam}/inventory`, { data: sendData })
}

/* Budtender Outlet Inventory Requests */
export const budtenderGetOutletInventory = async (outletParam) => {
    return axiosClient.get(`/budtender/outlets/${outletParam}/inventory`)
}
export const budtenderGetOutletInventoryProduct = async (outletParam, productParam) => {
    return axiosClient.get(`/budtender/outlets/${outletParam}/inventory?productId=${productParam}`)
}
export const budtenderAddOutletInventoryProduct = async (outletParam, sendData) => {
    return axiosClient.post(`/budtender/outlets/${outletParam}/inventory`, sendData)
}
export const budtenderEditOutletInventoryProduct = async (outletParam, sendData) => {
    return axiosClient.patch(`/budtender/outlets/${outletParam}/inventory`, sendData)
}
export const budtenderDeleteOutletInventoryProduct = async (outletParam, sendData) => {
    return axiosClient.delete(`/budtender/outlets/${outletParam}/inventory`, { data: sendData })
}


/* Admin Orders Requests */
export const adminGetOrders = async () => {
    return axiosClient.get("/admin/orders")
}
export const adminOrderDetails = async (orderParam) => {
    return axiosClient.get(`/admin/orders/${orderParam}`)
}
export const adminSetDriverForOrder = (orderParam, sendData) => {
    return axiosClient.patch(`/admin/orders/${orderParam}/set-driver`, sendData)
}
export const adminSetBudtenderForOrder = (orderParam, sendData) => {
    return axiosClient.patch(`/admin/orders/${orderParam}/set-budtender`, sendData)
}
export const adminSetScheduleForOrder = (scheduleParam, sendData) => {
    return axiosClient.patch(`/admin/orders/${scheduleParam}/schedule`, sendData)
}
export const adminReleaseOrder = (orderParam) => {
    return axiosClient.patch(`/admin/orders/${orderParam}/release`)
}
export const adminMarkOrderInTransit = (orderParam) => {
    return axiosClient.patch(`/admin/orders/${orderParam}/pick`)
}
export const adminMarkOrderDelivered = (orderParam, sendData) => {
    return axiosClient.patch(`/admin/orders/${orderParam}/deliver`, sendData)
}
export const adminCancelOrder = (orderParam, sendData) => {
    return axiosClient.patch(`/admin/orders/${orderParam}/cancel`, sendData)
} 

/* Admin Driver Order Requests */
export const adminGetDriverOrders = () => {
    return axiosClient.get("/driver/orders")
}
export const adminGetDriverOrderDetails = (orderParam) => {
    return axiosClient.get(`/driver/orders/${orderParam}`)
}
export const adminDriverMarkOrderInTransit = (orderParam) => {
    return axiosClient.patch(`/driver/orders/${orderParam}/pick`)
}
export const adminDriverMarkOrderDelivered = (orderParam, sendData) => {
    return axiosClient.patch(`/driver/orders/${orderParam}/deliver`, sendData)
}
export const adminDriverCancelOrder = (orderParam, sendData) => {
    return axiosClient.patch(`/driver/orders/${orderParam}/cancel`, sendData)
}

/* Admin Outlet Order Requests */
export const adminGetOutletOrders = async () => {
    return axiosClient.get("/budtender/orders")
}
export const adminGetOutletOrderDetails = async (orderParam) => {
    return axiosClient.get(`/budtender/orders/${orderParam}`)
}







