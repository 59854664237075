import { useHistory } from "react-router"
import { stringToPhoneFormat } from "../../Utils/StringFormatters"


const AdminUsersTableCell = ({ user }) => {
    let history = useHistory()

    const userNameLabel = () => {
        if (user.first_name && user.last_name) {
            return `${user.first_name} ${user.last_name}`
        }else if (user.email) {
            return user.email
        }else{
            return `!Missing Name!`
        }
    }

    const userPhoneLabel = () => {
        if (user.phone_number) {
            return stringToPhoneFormat(user.phone_number, true)
        }else{
            return "!Missing Phone!"
        }
    }

    const adminUsersTableCellClicked = () => {
        if (user._id) {
            history.push(`/myorders/users/${user._id}`)
        }
    }
    
    return (
        <tr key={user._id} onClick={adminUsersTableCellClicked}>
            <td className="admin-users-table-cell">
                <div>
                    <label>{userNameLabel()}</label>
                    <span>{userPhoneLabel()}</span>
                </div>
            </td>
        </tr>
    )
}

export default AdminUsersTableCell
