

const AdminControlsTableCell = () => {
    return (
        <tr>
            <td className="admin-controls-edit-table-cell">
                <div>
                    <label># of Drivers:</label>
                    <input type="number" inputMode="numeric"></input>
                </div>
                <div>
                    <label># of Orders Per Hour:</label>
                    <input type="number" inputMode="numeric"></input>
                </div>
                <div>
                    <label>Delivery Opens:</label>
                    <input type="time"></input>
                </div>
                <div>
                    <label>Delivery Closes:</label>
                    <input type="time"></input>
                </div>
                <div>
                    <label>Delivery Open or Closed (manual control):</label>
                    <select>
                        <option selected>Open</option>
                        <option>Closed</option>
                    </select>
                </div>
                <div>
                    <button type="submit">Save</button>
                </div>
            </td>
        </tr>
    )
}

export default AdminControlsTableCell
