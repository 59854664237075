
const NotFoundPage = () => {
    return (
        <div>
            404
        </div>
    )
}

export default NotFoundPage

