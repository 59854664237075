import axios from "axios"

const cached_auth_token = JSON.parse(localStorage["c9-token"] || null)
const cached_auth_access_token = cached_auth_token != null ? cached_auth_token.access_token : null

export let axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API}`
})

if (cached_auth_access_token != null) {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${cached_auth_access_token}`
}

axiosClient.interceptors.request.use(
    function (config) {
        document.body.classList.add("loading-indicator")
        return config
    }, 
    function (error) {
        document.body.classList.remove("loading-indicator")
        if (error.response != null && error.response.data != null && error.response.data.error != null && error.response.data.error.message != null) {
            alert(error.response.data.error.message)
        }
        return Promise.reject(error);
    }
)

axiosClient.interceptors.response.use(
    function (response) {
        document.body.classList.remove("loading-indicator")
        return response
    }, 
    function (error) {
        //console.log(error.response.data)
        document.body.classList.remove("loading-indicator")
        if (error.response != null && error.response.data != null && error.response.data.error != null && error.response.data.error.message != null) {
            alert(error.response.data.error.message)
        }
        return Promise.reject(error)
    }
)
