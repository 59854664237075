
/* Misc Regs */
const numericOnlyReg = /[^0-9\.]/g

/* Misc Helpers */
const doNumericFormat = (target) => {
    target.value = target.value.replace(numericOnlyReg, "")
}

/* Date Only Validator */
const dateFormat = "mm/dd/yyyy"
const dateMatch = new RegExp(dateFormat
    .replace(/(\w+)\W(\w+)\W(\w+)/, "^\\s*($1)\\W*($2)?\\W*($3)?([0-9]*).*")
    .replace(/m|d|y/g, "\\d"))
const dateReplace = "$1/$2/$3$4"
    .replace(/\//g, dateFormat.match(/\W/))
const doDateFormat = (target) => {
    target.value = target.value
    .replace(/(^|\W)(?=\d\W)/g, "$10")   // padding
    .replace(dateMatch, dateReplace)     // fields
    .replace(/(\W)+/g, "$1");            // remove repeats
}

export const dateOnly = (e, stateCallBack) => {
     doNumericFormat(e.target)

    if(!e.ctrlKey && !e.metaKey && (e.keyCode == 32 || e.keyCode > 46)) {
        doDateFormat(e.target)
    }
    if (stateCallBack != null) {
        stateCallBack(e.target.value)
    }
}

/* Phone Only Validator (US) */
const getDigitOnlyPhone = (value) => {
  return value.toString().replace(/\D/g, '').substring(0, 10)
}

const doFormattedPhone = (target) => {
    const value = target.value
    if (!value) return ''

    const phone = getDigitOnlyPhone(value)
    const areaCode = phone.substring(0, 3)
    const middle = phone.substring(3, 6)
    const last = phone.substring(6, 10)

    let formattedPhone = ''
    if (phone.length > 6) {
    formattedPhone = `(${areaCode}) ${middle}-${last}`
    } else if (phone.length > 3) {
    formattedPhone = `(${areaCode}) ${middle}`
    } else if (phone.length > 0) {
    formattedPhone = `(${areaCode}`
    }

    target.value = formattedPhone
}

export const phoneOnly = (e, stateCallBack) => {
    doFormattedPhone(e.target)
    
    if (stateCallBack != null) {
        stateCallBack(e.target.value)
    }
}