import { useContext } from "react"
import { Link } from "react-router-dom"
import { RoutesContext } from "../Utils/RoutesManager"
import { CartContext } from "../Utils/CartManager"
import AWSImage from "../Shared/AWSImage"
import Logo from "../Images/logo.png"

import "../Styles/CartPageStyles/cartCheckoutTableHeadCell.scss"

const CartCheckoutTableHeadCell = ({ mode }) => {
    const { baseOrganization, getGoToRouteForOrganization } = useContext(RoutesContext)
    const { cartDispensary, cartDispensaryID, cartLocationID, cartItems } = useContext(CartContext)

    const checkoutMode = mode === "checkout"
    let linkDisplay = checkoutMode ? "< Your Cart" : cartItems.length > 0 ? "< All Products" : "< Dispensaries"
    const linkTo = checkoutMode ? "/cart" : cartItems.length > 0 ? `/dispensary/${cartDispensaryID}/${cartLocationID}` : "/" 
    const locationDisplay = checkoutMode ? "Checkout" : "Your Cart"

    const area420Org = baseOrganization === "area420"

    if (area420Org) {
        linkDisplay = "< Categories"
    }

    return (
        <tr className="cart-checkout-table-head-cell">
            <td>
                <div>
                    <Link to={getGoToRouteForOrganization(linkTo)}>{linkDisplay}</Link>
                    <label>{locationDisplay}</label>
                </div>
                <div>
                    { cartDispensary != null &&
                        <AWSImage fileKey={cartDispensary.logo} />
                    }
                    { cartDispensary === null &&
                        <img src={Logo}></img>
                    }
                </div>
            </td>
        </tr>
    )
}

export default CartCheckoutTableHeadCell
