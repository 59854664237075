import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router"
import { RoutesContext } from "../components/Utils/RoutesManager"
import { CartContext } from "../components/Utils/CartManager"
import { stringToPhoneDataFormat } from "../components/Utils/StringFormatters"
import { awsPutObject } from "../components/Utils/awsHandler"
import moment from "moment"
import CheckoutTable from "../components/CheckoutPage/CheckoutTable"
import CartOrderDetailsTable from "../components/CartPage/CartOrderDetailsTable"

import "../components/Styles/CheckoutPageStyles/checkoutPage.scss"
import { cartCheckout } from "../components/Utils/APIManager"

const CheckoutPage = () => {
    let history = useHistory()
    const { baseOrganization, getGoToRouteForOrganization } = useContext(RoutesContext)
    const { cartItems, cartDispensary, cartDispensaryID, cartLocationID, getOrderTotalInCents, cartDeliveryFee } = useContext(CartContext)

    const [deliveryAddress, setDeliveryAddress] = useState(null)
    const [deliveryAddress2, setDeliveryAddress2] = useState(null)
    const [deliveryAddressComponents, setDeliveryAddressComponents] = useState(null)

    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [birthDate, setBirthdate] = useState(null)
    const [mobilePhone, setMobilePhone] = useState(null)
    const [photoID, setPhotoID] = useState(null)
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false)

    const [patientRegistrationNumber, setPatientRegistrationNumber] = useState(null)

    const [dispensaryName, setDispensaryName] = useState(null)
    const [dispensaryLicenseNumber, setDispensaryLicenseNumber] = useState(null)

    const isRecreationalOutlet = cartDispensary.outlet_type === "retail-rec"
    const isMedicalOutlet = cartDispensary.outlet_type === "retail-med"
    const isWholeSaleOutlet = cartDispensary.outlet_type === "wholesale"

    const firstNameInvalid = () => {
        return (firstName === null || firstName.length === 0)
    }
    const lastNameInvalid = () => {
        return (lastName === null || lastName.length === 0)
    }
    const birthDateInvalid = () => {
        let validAge = false
        if (birthDate != null && birthDate.length === 10) {
            const currentTime = new Date().getTime()
            const birthDateTime = new Date(birthDate).getTime()
            const difference = (currentTime - birthDateTime)
            const ageInYears = difference / (1000*60*60*24*365)
            validAge = ageInYears >= 21
        }

        return (birthDate === null || birthDate.length === 0 || !validAge)
    }
    const mobilePhoneInvalid = () => {
        let phoneValid = false
        if (mobilePhone != null) {
            phoneValid = mobilePhone.length === 14
        }

        return (mobilePhone === null || mobilePhone.length === 0 || !phoneValid)
    }
    const photoIDInvalid = () => {
        return photoID === null
    }
    const patientRegistrationNumberInvalid = () => {
        return (patientRegistrationNumber === null || patientRegistrationNumber.length === 0)
    }
    const dispensaryNameInvalid = () => {
        return (dispensaryName === null || dispensaryName.length === 0)
    }
    const dispensaryLicenseNumberInvalid = () => {
        return (dispensaryLicenseNumber === null || dispensaryLicenseNumber.length === 0)
    }

    useEffect(() => {

        const delivery_address = history.location.state?.delivery_address
        const delivery_address2 = history.location.state?.delivery_address2
        const delivery_address_components = history.location.state?.delivery_address_components

        if (delivery_address === undefined || delivery_address === null || delivery_address_components === undefined || delivery_address_components === null) {
            history.push(getGoToRouteForOrganization("/cart"))
        }else{
            setDeliveryAddress(delivery_address)
            setDeliveryAddress2(delivery_address2)
            setDeliveryAddressComponents(delivery_address_components)
        }

    }, [mobilePhone])

    const formErrorMessage = () => {
        let errorMessage = ""
        if (firstNameInvalid()) {
            errorMessage += "<span>• First Name is Required</span>"
        }
        if (lastNameInvalid()) {
            errorMessage += "<span>• Last Name is Required</span>"
        }
        if (birthDateInvalid()) {
            errorMessage += "<span>• You are Required to be 21 or older</span>"
        }
        if (mobilePhoneInvalid()) {
            errorMessage += "<span>• Mobile Phone is Invalid</span>"
        }


        if (isWholeSaleOutlet) {
            if (dispensaryNameInvalid()) {
                errorMessage += "<span>• Dispensary Name is Required</span>"
            }
            if (dispensaryLicenseNumberInvalid()) {
                errorMessage += "<span>• Dispensary License Number is Required</span>"
            }
        }else{

            if (isMedicalOutlet) {
                if (patientRegistrationNumberInvalid()) {
                    errorMessage += "<span>• Patient Registration Number is Required</span>"
                }
            }

            if (photoIDInvalid()) {
                errorMessage += "<span>• Photo ID is Required</span>"
            }
        }

        return errorMessage.length === 0 ? null : errorMessage
    }

    const checkoutFormProps = {
        deliveryAddress,
        deliveryAddress2,
        setFirstName,
        setLastName,
        setBirthdate,
        setMobilePhone,
        setPhotoID,
        setPatientRegistrationNumber,
        setDispensaryName,
        setDispensaryLicenseNumber,
        firstNameInvalid,
        lastNameInvalid,
        birthDateInvalid,
        mobilePhoneInvalid,
        photoIDInvalid,
        patientRegistrationNumberInvalid,
        dispensaryNameInvalid,
        dispensaryLicenseNumberInvalid,
        formErrorMessage,
        showFormErrorMessage
    }

    const checkoutFormSubmitted = async (e) => {
        e.preventDefault()
        
        const formErrors = formErrorMessage()
        const noErrors = formErrors === null
        
        if (noErrors) {
            setShowFormErrorMessage(false)

            let photo_url = ""
            if (isRecreationalOutlet || isMedicalOutlet) {
                const dateNow = moment(Date.now()).format('MM-DD-YY')
                const timeNow = moment(Date.now()).format("hh-mm")
                const fileName = `cdl/${dateNow}/${timeNow}`
                let file = new File([photoID], fileName, { type: "image/png", lastModified: Date.now() })
                const uplRes = await awsPutObject(file, fileName)
                if (uplRes.$response != null && uplRes.$response.httpResponse.statusMessage === "OK") {
                    photo_url = fileName
                }
            }


            const sendData = {
                "order": {
                    "outlet": cartDispensaryID,
                    "locationId": cartLocationID,
                    "address": {
                        "street1": deliveryAddressComponents.streetNumName,
                        "street2": (deliveryAddress2 != null && deliveryAddress2 != undefined) ? deliveryAddress2 : "",
                        "city": deliveryAddressComponents.city1,
                        "state": deliveryAddressComponents.state,
                        "zip": deliveryAddressComponents.postalCode
                    },
                    "customer": {
                        "firstName": firstName,
                        "lastName": lastName,
                        "birthDate": new Date(birthDate),
                        "externalId": cartDeliveryFee, // Setting Delivery Fee to Customer ExternalId Temporarily (need an actual key)
                        "email": "support@cloud9delivers.com",
                        "phone": stringToPhoneDataFormat(mobilePhone),
                        "medRecOrBoth": isRecreationalOutlet ? "rec" : isMedicalOutlet ? "med" : "",
                        "medId": patientRegistrationNumber != null ? patientRegistrationNumber : "",
                        "medExp": ""
                    },
                    "customer_licc": photo_url,
                    "patient_registration_number": patientRegistrationNumber,
                    "business_name": dispensaryName,
                    "business_number": dispensaryLicenseNumber,
                    "total_in_cents": getOrderTotalInCents(),
                    "orderItems": formatOrderItems()
                }
            }

            const checkout_response = await cartCheckout(sendData)
            if (checkout_response.data != null) {
                history.push({
                    pathname: getGoToRouteForOrganization("/order-confirmation"),
                    state: { checked_out: true, order: checkout_response.data.newOrder }
                })
            }

        }else{
            setShowFormErrorMessage(true)
        }
    }

    const formatOrderItems = () => {
        let orderItems = []
        for (let i = 0; i < cartItems.length; i++) {
            const item = cartItems[i]
            if (item.product != null && item.quantity != null) {
                orderItems.push({
                    "productName": item.product.productName, 
                    "productId": item.product._id,
                    "productGrams": item.gramAmount != null ? item.gramAmount : null,
                    "quantityPurchased": item.quantity
                })
            }
        }

        return orderItems
    }

    return (
        <div className="checkout-page-container">
            <form onSubmit={checkoutFormSubmitted}>
                <CheckoutTable props={checkoutFormProps} />
            </form>

            <CartOrderDetailsTable mode={"checkout"} />
        </div>
    )
}

export default CheckoutPage
